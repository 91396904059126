import { ACCESS_STATUS_CONFIG_KEY, FIELD_CONFIGS } from './constants';
import {
  CurrentRequestData,
  PortalConfigValues,
  StudentMetaData,
  PortalConfigKeypath,
  Course,
  ZgOption,
  PRIORITY,
  REQUEST_STATUS,
} from './types';

const ALL_TABS: { label: string; config: PortalConfigKeypath; type: string }[] = [
  { label: 'Course Requests', config: 'default', type: 'currentCourseRequests' },
  { label: 'Previous Courses', config: 'show_previous_courses', type: 'previousCourses' },
  { label: 'Credit Report', config: 'show_credit_report', type: 'creditReport' },
  { label: 'Current Schedule', config: 'show_current_schedule', type: 'currentSchedule' },
];

export const enabledTabs = (portalConfig: StudentMetaData['portalConfig']) => {
  return ALL_TABS.filter(
    (tab) =>
      portalConfig
        .filter(
          (config) =>
            ['show_previous_courses', 'show_credit_report', 'show_current_schedule'].includes(
              config.keypath
            ) && config.value === 'true'
        )
        .map((config) => config.keypath)
        .includes(tab.config) || tab.config === 'default'
  );
};

export const initStudent = (personPk: number) =>
  personPk
    ? {
        person_pk: personPk,
        name: '',
        first_name: '',
        last_name: '',
      }
    : null;

export const isReadOnlyConfig = (config: PortalConfigValues) =>
  config.find((c) => c.keypath === ACCESS_STATUS_CONFIG_KEY)?.value === 'read-only';

export const hasFieldsConfigured = (config: PortalConfigValues) =>
  config.some((c) => FIELD_CONFIGS.includes(c.keypath) && c.value === 'true');

export const isRequestLockedByApproval = (request: CurrentRequestData, isFacultyPortal: boolean) =>
  request?.approval_status === REQUEST_STATUS.Approved && !isFacultyPortal;

export const isRequestLockedByRecommended = (
  request: CurrentRequestData,
  isFacultyPortal: boolean
) =>
  !isRequestLockedByApproval(request, isFacultyPortal) && request?.recommended && !isFacultyPortal;

export const showField = (keypath: PortalConfigKeypath, portalConfig: PortalConfigValues) => {
  return (portalConfig.find((config) => config.keypath === keypath) || {}).value === 'true';
};

export const courseDescription = (course: Course) => {
  return course.course_id ? `${course.course_id + ': ' + course.description}` : course.description;
};

export const filterCoursesBySubject = (
  subjectId,
  subjectCourses,
  searchInput,
  classificationFilters
): Course[] => {
  let courses = subjectCourses[subjectId] || [];

  courses = courses.filter((course) => {
    return searchInput && searchInput.length > 0
      ? courseDescription(course).toLowerCase().includes(searchInput.toLowerCase())
      : true;
  });
  courses = courses.filter((course) => {
    return classificationFilters && classificationFilters.length > 0
      ? classificationFilters.some((classification) =>
          course.classifications.split(', ').includes(classification)
        )
      : true;
  });

  return courses;
};

export const getDefaultGradingPeriod = (
  gradingPeriods: ZgOption[],
  activeGradingPeriod: number
): number => {
  return gradingPeriods.some((period) => period.id === activeGradingPeriod)
    ? activeGradingPeriod
    : gradingPeriods[0].id;
};

export const getDefaultPriority = (requestGroup: number, currentRequests: CurrentRequestData[]) => {
  const numberOfRequestsInGroup = currentRequests.filter(
    (r) => r.request_group === requestGroup
  ).length;
  if (numberOfRequestsInGroup === 1) {
    return PRIORITY.Second;
  }
  return PRIORITY.Third;
};

export const userApprovedRequest = (courseRequest, isFacultyPortal, userType): boolean => {
  return isFacultyPortal
    ? courseRequest[`has_${userType}_approval`]
    : hasAnyApprovals(courseRequest);
};

export const getLabels = (portalConfig: PortalConfigValues) => {
  const advisorApprovalLabel = portalConfig
    .find((config) => config.keypath === 'advisor_approval_label')
    ?.value?.trim()
    ?.substring(0, 30);
  const homeroomApprovalLabel = portalConfig
    .find((config) => config.keypath === 'homeroom_approval_label')
    ?.value?.trim()
    ?.substring(0, 30);

  return {
    teacher: 'Teacher',
    advisor: advisorApprovalLabel || 'Advisor',
    homeroom: homeroomApprovalLabel || 'Homeroom Teacher',
  };
};

export const hasAnyApprovals = (courseRequest: CurrentRequestData): boolean => {
  return (
    courseRequest.has_teacher_approval ||
    courseRequest.has_advisor_approval ||
    courseRequest.has_homeroom_approval ||
    courseRequest.approval_status === REQUEST_STATUS.Approved
  );
};

export const approvedWithoutUserApproval = (
  courseRequest: CurrentRequestData,
  requestApproved: boolean,
  hasUserApproval: boolean
): boolean => requestApproved && !hasUserApproval && courseRequest.single_approval_required;

export const readOnlyApprovalField = (
  courseRequest: CurrentRequestData,
  userType: string,
  approvedByOther: boolean
): boolean =>
  courseRequest[`allow_${userType}_approval`] === undefined ||
  (approvedByOther && courseRequest.approvals_required.includes(' and '));
