import moment from 'moment';
import { Exam } from './types';

interface WeekDay {
  date: string;
  exams: Exam[];
}

export const getBasePath = (): string => {
  // converts:
  // '/aabbots2021@vcsandbox/students/student/exams/exam/123'
  // into:
  // '/aabbots2021@vcsandbox/students/student/exams'

  // or parent portal path:
  // '/aabbots2021@vcsandbox/parents/student/{student_id}/exams/exam/123'
  // into:
  // '/aabbots2021@vcsandbox/parents/student/{student_id}/exams'

  // This is needed because the base path is variable, depending on the user
  // and we want the router to ignore that part
  const path = window.location.pathname;
  const segments = path.split('/');

  // Check if the segment before 'exams' is numeric (student ID)
  const examsIndex = segments.indexOf('exams');
  const isParentPortalPath = !isNaN(Number(segments[examsIndex - 1]));

  // For parent portal paths, we need one more segment to include the student ID
  const segmentCount = isParentPortalPath ? 6 : 5;
  return segments.slice(0, segmentCount).join('/');
};

export const calculateWeekDays = (currentWeekStart: moment.Moment, exams: Exam[]): WeekDay[] => {
  const days: WeekDay[] = [];
  for (let i = 0; i < 7; i++) {
    const day = currentWeekStart.clone().add(i, 'days');
    const dayExams = exams.filter(
      (exam) => moment(exam.date, 'YYYY-MM-DD').format('YYYY-MM-DD') === day.format('YYYY-MM-DD')
    );
    days.push({
      date: day.format('YYYY-MM-DD'),
      exams: dayExams,
    });
  }
  return days;
};

export const getTodayExamsCount = (exams: Exam[], today: string): number => {
  return exams.filter(
    (exam) => moment(exam.date).format('YYYY-MM-DD') === moment(today).format('YYYY-MM-DD')
  ).length;
};

export const getTomorrowExamsCount = (exams: Exam[], today: string): number => {
  return exams.filter(
    (exam) =>
      moment(exam.date).format('YYYY-MM-DD') === moment(today).add(1, 'days').format('YYYY-MM-DD')
  ).length;
};

export const getWeekExamsCount = (weekDays: { exams: Exam[] }[]): number => {
  return weekDays.reduce((total, day) => total + day.exams.length, 0);
};

export const isCurrentWeekCheck = (weekDays: { date: string }[], today: string): boolean => {
  return weekDays.some(({ date }) => date === today);
};

export const isFutureWeekCheck = (
  weekDays: { date: string }[],
  today: string,
  isCurrentWeek: boolean
): boolean => {
  return !isCurrentWeek && weekDays.some(({ date }) => moment(date).isAfter(moment(today), 'day'));
};

export const formatValue = (value: string | null | undefined): string => {
  return !value || value === '<None Specified>' ? '-' : value;
};
