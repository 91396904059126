import { Exam, ExamEntry } from './types';

export const mockExamsSameDay: Exam[] = [
  {
    date: '2024-03-20',
    end_time_calculated: '11:00',
    exam_session_fk: 1,
    exam_session_description: 'Math Exam',
    exam_season_description: 'Spring 2024',
    exam_season_fk: 1001,
    room_description: 'Room 101',
    start_time_calculated: '09:00',
    qualification_description: 'GCSE Mathematics',
    awarding_body_description: 'AQA',
    attendance_taken_on: null,
  },
  {
    date: '2024-03-20',
    end_time_calculated: '15:00',
    exam_session_fk: 2,
    exam_session_description: 'Science Exam',
    exam_season_description: 'Spring 2024',
    exam_season_fk: 1001,
    room_description: 'Lab 201',
    start_time_calculated: '13:00',
    qualification_description: 'GCSE Science',
    awarding_body_description: 'AQA',
    attendance_taken_on: null,
  },
  {
    date: '2024-03-20',
    end_time_calculated: '17:30',
    exam_session_fk: 3,
    exam_session_description: 'History Exam',
    exam_season_description: 'Spring 2024',
    exam_season_fk: 1001,
    room_description: 'Room 305',
    start_time_calculated: '15:30',
    qualification_description: 'GCSE History',
    awarding_body_description: 'AQA',
    attendance_taken_on: null,
  },
];

export const mockExamsWeek: Exam[] = [
  {
    date: '2024-03-15',
    end_time_calculated: '12:00',
    exam_session_fk: 1,
    exam_session_description: 'Math Exam',
    exam_season_description: 'Spring 2024',
    exam_season_fk: 1001,
    room_description: 'Room 101',
    start_time_calculated: '10:00',
    qualification_description: 'GCSE Mathematics',
    awarding_body_description: 'AQA',
    attendance_taken_on: null,
  },
  {
    date: '2024-03-15',
    end_time_calculated: '16:00',
    exam_session_fk: 2,
    exam_session_description: 'Science Exam',
    exam_season_description: 'Spring 2024',
    exam_season_fk: 1001,
    room_description: 'Lab 201',
    start_time_calculated: '14:00',
    qualification_description: 'GCSE Science',
    awarding_body_description: 'AQA',
    attendance_taken_on: null,
  },
  {
    date: '2024-03-20',
    end_time_calculated: '11:00',
    exam_session_fk: 3,
    exam_session_description: 'History Exam',
    exam_season_description: 'Spring 2024',
    exam_season_fk: 1001,
    room_description: 'Room 305',
    start_time_calculated: '09:00',
    qualification_description: 'GCSE History',
    awarding_body_description: 'AQA',
    attendance_taken_on: null,
  },
];

export const mockExamDetail: Exam = {
  exam_session_description: 'Math Final Exam',
  date: '2024-03-20',
  room_description: 'Room 101',
  start_time_calculated: '09:00',
  end_time_calculated: '11:00',
  qualification_description: 'GCSE Mathematics',
  exam_season_description: 'Spring 2024',
  awarding_body_description: 'AQA',
  exam_session_fk: 1,
  exam_season_fk: 1001,
  attendance_taken_on: null,
};

export const mockExamEntry: ExamEntry = {
  grade: '99.99',
  exam_entry_pk: 1001,
  exam_candidacy_fk: 2001,
  exam_entry_components: [
    {
      exam_entry_component_pk: 1,
      description: 'Written Paper',
      grade: 'A',
      qualification_component_pk: 3001,
      exam_component_scores: [
        {
          description: 'Section 1',
          score: '45/50',
        },
      ],
    },
  ],
};
