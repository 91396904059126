export type PortalConfigKeypath =
  | 'default'
  | 'enabled'
  | 'advisees_only'
  | 'default_approval_status'
  | 'default_level'
  | 'default_priority'
  | 'default_term'
  | 'show_approved'
  | 'show_credit_report'
  | 'show_current_schedule'
  | 'show_level'
  | 'show_notes'
  | 'show_previous_courses'
  | 'show_priority'
  | 'show_recommended'
  | 'show_term'
  | 'advisor_approval_label'
  | 'homeroom_approval_label';

export type PortalConfigValue = {
  system_category: 'course_requests';
  keypath: PortalConfigKeypath;
  value: string;
};

export type PortalConfigValues = PortalConfigValue[];

export type StudentMetaData = {
  isFacultyPortal: boolean;
  student?: Student;
  studentData: StudentInfo;
  studentIsSelected?: boolean;
  version: number;
  portalConfig: PortalConfigValues;
  readOnly: boolean;
  activeGradingPeriod: number;
};

export type CourseRequestData = {
  current_course_requests: CurrentRequestData[];
  previous_courses: PreviousCourse[];
  credits: CreditReport[];
  current_schedule: string;
  grading_periods: { id: number; description: string }[];
  enrollment_levels: ZgOption[];
  subjects: ZgOption[];
  courses_by_subject: Record<number, Course[]>;
  classifications: ZgOption[];
  default_form_values: CourseRequestFormData;
  current_grades: CurrentGrade[];
};

export type Actions = {
  initialize: (studentState: StudentMetaData) => any;
  setStudent: (newStudent: Student) => void;
  setStudentData: (newData: StudentInfo) => void;
  setCourseRequestData: (newData: CourseRequestData) => void;
  setRequestField: (newRequestField: {
    field: string;
    value: string | number | boolean;
    id: number;
  }) => void;
};

export type CourseRequestState = {
  studentState: StudentMetaData;
  courseRequestState: CourseRequestData;
  actions: Actions;
};

export type Student = {
  person_pk: number;
  name: string;
  first_name: string;
  last_name: string;
  class_pks?: string;
};

export type StudentInfo = {
  full_name: string;
  grade_applying_for: string;
  school_year: number;
  sy_description_long: number;
  campus: string;
  student_group: string;
  course_requests_finalized: boolean;
  schedule_url: string;
  user_type: string;
};

export type StudentList = Student & { label: string };

export type ClassList = {
  class_pk: number;
  class_id: string;
  description: string;
  school_year: number;
  status: number;
  todays_start_time: string;
  todays_end_time: string;
  label?: string;
};

export type CurrentRequestData = {
  approval_status: number;
  has_teacher_approval: boolean;
  has_advisor_approval: boolean;
  has_homeroom_approval: boolean;
  allow_teacher_approval: boolean;
  allow_advisor_approval: boolean;
  allow_homeroom_approval: boolean;
  single_approval_required: boolean;
  classifications: string;
  course: string;
  course_id: string;
  subject: string;
  link_style: string;
  course_pk: number;
  enrolled: boolean;
  enrollment_level: number;
  grading_period: number;
  id: number;
  notes: string;
  priority: number;
  recommended: boolean;
  relationships: string;
  request_group: number;
  credits: number;
  approvals_currently_needed: string;
  approvals_required: string;
};

export type ZgOption = {
  id: number;
  description: string;
};

export type Course = {
  id: number;
  description: string;
  course_id: number | null;
  subject: string;
  subject_id: number;
  search_string: string;
  link_style: string;
  notes: string | null;
  relationships: any | null;
  classifications: string;
  requested: boolean;
  recommended: number;
};

export type CourseRequestFormData = {
  course: number;
  priority: number;
  grading_period: number;
  enrollment_level: number;
  approval_status: number;
  has_teacher_approval?: boolean;
  has_advisor_approval?: boolean;
  has_homeroom_approval?: boolean;
  require_teacher_approval?: boolean;
  require_advisor_approval?: boolean;
  require_homeroom_approval?: boolean;
  recommended: boolean;
  notes: string;
  request_group?: number;
};

export type CourseRequestUpdateData = {
  id: number;
  field: string;
  value: string | number | boolean;
};

export type PreviousCourse = {
  course: string;
  grade_level: string;
  subject: string;
  year: number;
  teacher_name: string;
  class_name: string;
};

export type AddCourseRequestModalData = {
  isOpen: boolean;
  requestGroup?: number;
};

export type CurrentGrade = {
  class_name: string;
  numeric_grade: number;
  letter_grade: string;
  average: number;
  grading_period: number;
  gp_description: string;
  grade_detail_url: string;
  has_view_grades_permission: boolean;
  include_grades_flag: boolean;
};

export type CreditReport = {
  credits_earned: number;
  credits_required: number;
  needed: number;
  scheduled: number;
  subject: string;
  will_need: number;
};

export enum ApprovalStatus {
  Unapproved = 0,
  Approved = 1,
}

export enum PRIORITY {
  First = 1,
  Second = 2,
  Third = 3,
}

export enum REQUEST_STATUS {
  Pending = 0,
  Approved = 1,
  Denied = 2,
}

export const DEFAULT_PRIORITY_OPTIONS: ZgOption[] = [
  { id: 0, description: 'Not Specified' },
  { id: 1, description: 'First' },
  { id: 2, description: 'Second' },
  { id: 3, description: 'Third' },
];
