import React, { useEffect, useState } from 'react';
import { useCourseRequestState, useStudentState } from '../../../../stores/useCourseRequestsStore';
import RequestCardBody from './RequestCardBody';
import { showField } from '../helpers';
import { CREDITS_HELP_TEXT } from '../constants';
import AllCourses from './AllCourses';
import DeleteCourseRequestModal from '../V2/DeleteCourseRequestModal';
import LoadingSpinner from '../../../shared/LoadingSpinner';

type CourseRequestsListProps = {
  refetchList: () => void;
  isLoadingList: boolean;
};

const CourseRequestsList = ({ refetchList, isLoadingList }: CourseRequestsListProps) => {
  const { current_course_requests: currentCourseRequests } = useCourseRequestState();
  const { portalConfig, readOnly } = useStudentState();
  const [scrollToRequest, setScrollToRequest] = useState<number>(null);
  const [modalRemovingCard, setModalRemovingCard] = useState<number>(null);

  let totalCredits = 0;
  currentCourseRequests.forEach((request) => {
    totalCredits += request.credits;
  });

  useEffect(() => {
    if (scrollToRequest && !isLoadingList && currentCourseRequests.length) {
      const element = document.getElementById(`request-${scrollToRequest}`);

      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        setScrollToRequest(null);
      }
    }
  }, [currentCourseRequests, scrollToRequest, isLoadingList]);

  useEffect(() => {
    if (scrollToRequest) {
      refetchList();
    }
  }, [scrollToRequest]);

  const totalCourseRequests = currentCourseRequests.length;
  const tooltipClasses =
    'invisible group-hover:visible absolute -top-[34px] right-[52px] w-32 bg-[#000000CC] rounded-sm after:absolute after:top-[40%] after:-right-3.5 after:border-8 after:border-l-[#000000CC] after:border-y-transparent after:border-r-transparent text-white text-left font-normal pt-3 px-1 pb-1';

  if (isLoadingList) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {currentCourseRequests.length > 0 && (
        <>
          <div className="overflow-x-auto overflow-visible relative min-h-[18rem] max-md:pb-2">
            <table className="w-full text-xs">
              <caption className="pb-2 font-bold text-right lg:mr-8">
                Total Credits: {totalCredits.toFixed(2)}
              </caption>
              <thead className="bg-gray-100 font-bold border-b-[1px] border-gray-300">
                <tr>
                  <th className="w-2"></th>
                  <th className="pl-2 pr-1 w-12 text-left">{readOnly ? 'Enrolled' : 'Group'}</th>
                  <th className="px-1 w-52 text-left">Course</th>
                  {showField('show_notes', portalConfig) && (
                    <th className="px-1 w-48 text-left">Notes</th>
                  )}
                  {showField('show_priority', portalConfig) && (
                    <th className="px-1 w-28 text-left">Priority</th>
                  )}
                  {showField('show_term', portalConfig) && (
                    <th className="px-1 w-28 text-left">Term</th>
                  )}
                  {showField('show_recommended', portalConfig) && (
                    <th className="px-1 w-[85px] text-left">Recommended</th>
                  )}
                  {showField('show_approved', portalConfig) && (
                    <th className="px-1 w-[85px]">Approved</th>
                  )}
                  <th className="w-auto">{/* empty */}</th>
                  <th className="px-1 w-12 group relative cursor-help">
                    Credits
                    <span className={tooltipClasses}>{CREDITS_HELP_TEXT}</span>
                  </th>
                  <th className="w-[84px]">{/* empty */}</th>
                </tr>
              </thead>
              <tbody className="w-full">
                {currentCourseRequests?.map((request, index) => {
                  return (
                    <RequestCardBody
                      key={request.id}
                      requestProps={request}
                      index={index}
                      totalRequests={totalCourseRequests}
                      setModalRemovingCard={setModalRemovingCard}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
          <DeleteCourseRequestModal
            modalRemovingCard={modalRemovingCard}
            setModalRemovingCard={setModalRemovingCard}
            refetchList={refetchList}
          />
        </>
      )}

      {!readOnly && <AllCourses setScrollToRequest={setScrollToRequest} />}
    </>
  );
};

export default CourseRequestsList;
