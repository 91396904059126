import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import ExamSessionList from './ExamSessionList';
import LoadingSpinner from '../../shared/LoadingSpinner';
import ExamCalendar from './ExamCalendar';
import moment from 'moment';
import EmptyPage from '../../shared/EmptyPage';
import { useExamSessions } from './queries';
import { PortalType } from './types';

type LandingPageProps = {
  personId: number;
  authToken: string;
  today: string;
  portalType: PortalType;
};

const LandingPage = ({ today, portalType, personId, authToken }: LandingPageProps) => {
  const { data: exams, isLoading, error } = useExamSessions(personId, authToken);
  const [selectedWeekStart, setSelectedWeekStart] = useState(
    moment(today).startOf('isoWeek').format('YYYY-MM-DD')
  );
  const [highlightedDate, setHighlightedDate] = useState<string | null>(null);
  const [selectedTab, setSelectedTab] = useState<'upcoming' | 'past'>('upcoming');

  useEffect(() => {
    if (exams) {
      const hasUpcomingExams = exams.some((exam) =>
        moment(exam.date, 'YYYY-MM-DD').isSameOrAfter(moment(today, 'YYYY-MM-DD'), 'day')
      );
      setSelectedTab(hasUpcomingExams ? 'upcoming' : 'past');
    }
  }, [exams]);

  const tabClasses = (tabName) => {
    return classNames('vx-page-tabs__tab', {
      'vx-page-tabs__tab--active': selectedTab === tabName,
    });
  };

  const filteredExams = (exams || []).filter((exam) => {
    const examDate = moment(exam.date, 'YYYY-MM-DD');
    const todayDate = moment(today, 'YYYY-MM-DD');
    return selectedTab === 'upcoming'
      ? examDate.isSameOrAfter(todayDate, 'day')
      : examDate.isBefore(todayDate, 'day');
  });

  const handleShowInCalendar = (date: string) => {
    setSelectedWeekStart(moment(date).startOf('isoWeek').format('YYYY-MM-DD'));
    setHighlightedDate(date);
    window.scrollTo({ top: 0, behavior: 'smooth' });

    setTimeout(() => {
      setHighlightedDate(null);
    }, 2000);
  };

  if (error?.message === 'User not authorized') {
    return (
      <EmptyPage
        message="You do not have permission to view"
        subMessage="Contact your school administrator"
        icon="ui-1_lock"
      />
    );
  }

  if (error) {
    return (
      <EmptyPage
        message="Something went wrong"
        subMessage="Please refresh the page"
      />
    );
  }

  return (
    <>
      {exams && (
        <ExamCalendar
          exams={exams}
          today={today}
          selectedWeekStart={selectedWeekStart}
          setSelectedWeekStart={setSelectedWeekStart}
          highlightedDate={highlightedDate}
        />
      )}
      <div className="student-overview mt-10">
        <h3 className="vx-heading-3 student-overview-heading">Exam Sessions</h3>
        <ul className="vx-page-tabs class-tabs">
          <li
            className={tabClasses('past')}
            onClick={() => setSelectedTab('past')}
          >
            Past
          </li>
          <li
            className={tabClasses('upcoming')}
            onClick={() => setSelectedTab('upcoming')}
          >
            Upcoming
          </li>
        </ul>

        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <ExamSessionList
            exams={filteredExams}
            onShowInCalendar={handleShowInCalendar}
            portalType={portalType}
          />
        )}
      </div>
    </>
  );
};

export default LandingPage;
