require('../../locales/en-in');
require('../../locales/en-ca');

window.Sys.InvoicePayment = {
  init: function (locale) {
    numeral.register('locale', 'en-in', Locales.en_in);
    numeral.register('locale', 'en-ca', Locales.en_ca);

    // this is a hacky way to match the spacing in formats spit out by number_to_currency
    // so that we have consistent rendering of formats when the javascript does its thing.
    // numeral.js doesn't have a way to configure spacing based on locale
    numeral.locale(locale.toLowerCase());
    if (locale == 'en') {
      numeral.defaultFormat('$0,0.00');
    } else {
      numeral.defaultFormat('$ 0,0.00');
    }

    this.formatMoneyInputs();
    this.calculateTotals();
    // debouncing so the paste event works correctly
    $('.amount-input').on('change paste', _.debounce(this.formatMoneyInputs, 100));
    $('.amount-input').on('change paste keyup', _.debounce(this.calculateTotals, 100));

    $('.unlock-auto-pay-button').click(this.unlockAutoPay);
    $('.pay-now').click(this.payButtonClicked);
  },

  calculateTotals: function () {
    var sum = 0;
    $('.amount-input').each(function () {
      var sanitizedNumber = numeral($(this).val());

      // bad value entered
      if (sanitizedNumber.value() == null || sanitizedNumber.value() < 0) {
        sanitizedNumber.set(0);
      }

      var totalBilled = numeral(
        $(this).closest('tr').find('.current-balance').data('total')
      ).value();
      var totalPending = numeral($(this).closest('tr').find('.pending').data('pending')).value();

      // Check if they are attempting to pay more than the invoice balance
      // One consideration here is that the total billed could be negative. This could happen if the parent has credits on the invoice
      if (sanitizedNumber.value() > 0 && sanitizedNumber.value() + totalPending > totalBilled) {
        $(this).addClass('warning');
      } else {
        $(this).removeClass('warning');
      }

      sum += sanitizedNumber.value();
    });
    $('.total.payment-amount,.total-payment-amount').text(numeral(sum).format());

    // if any have warning show a warning to user
    if ($('.amount-input.warning').length > 0) {
      $('.overpay-warning').fadeIn();
    } else {
      $('.overpay-warning').hide();
    }

    // don't allow submission if no amounts are entered
    $('.pay-now').prop('disabled', sum <= 0);
  },

  formatMoneyInputs: function () {
    $('.amount-input').each(function () {
      var sanitizedNumber = numeral($(this).val());

      // bad value entered
      if (sanitizedNumber.value() == null || sanitizedNumber.value() < 0) {
        sanitizedNumber.set(0);
      }

      $(this).val(sanitizedNumber.format('0,0.00'));
    });
  },

  unlockAutoPay: function (e) {
    $(e.currentTarget).remove(); // remove the button entirely
    $('.payment-amount.locked').removeClass('locked');
  },

  payButtonClicked: function (e) {
    e.preventDefault();

    // if any have warning show a warning to user
    if ($('.amount-input.warning').length > 0) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You are about to pay more than the amount owed on one or more of your invoices.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Yes, overpay!',
      }).then((result) => {
        if (result.value) {
          $('form').submit();
        }
      });
    } else {
      $('form').submit();
    }
  },
};

_.bindAll(Sys.InvoicePayment, [
  'init',
  'calculateTotals',
  'formatMoneyInputs',
  'unlockAutoPay',
  'payButtonClicked',
]);
