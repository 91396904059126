window.Locales = window.Locales || {};
window.Locales.en_ca = {
  delimiters: {
    thousands: ',',
    decimal: '.',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal: function (number) {
    return number;
  },
  currency: {
    symbol: '$',
  },
};
window.Locales['en-ca'] = window.Locales.en_ca;
