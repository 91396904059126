import { useMutation, useQueryClient } from '@tanstack/react-query';
import { makeRequest, PORTALS_REQUEST_URL_PARAMS } from '../common/request';
import { AttendanceStatus, Exam, ExamIncident } from './types';

export type ExamCandidateSessionUpdateParams = {
  attendance_status?: AttendanceStatus;
  notes?: string;
  actual_start_time?: string;
  actual_end_time?: string;
};

async function updateExamCandidateSession(
  authToken: string,
  examCandidateSessionPk: number,
  personFk: number,
  examSessionPk: number,
  body: ExamCandidateSessionUpdateParams
) {
  const url = Routes.exam_session_update_student_path({
    ...PORTALS_REQUEST_URL_PARAMS,
    exam_candidate_session_pk: examCandidateSessionPk,
  });

  const payload = {
    person_fk: personFk,
    exam_session_pk: examSessionPk,
    ...body,
  };

  return await makeRequest(url, 'PUT', authToken, payload);
}

export type ExamSessionUpdateParams = {
  attendance_taken_on: Date;
};

async function updateExamSession(
  authToken: string,
  personFk: number,
  examSessionPk: number,
  body: ExamSessionUpdateParams
) {
  const url = Routes.exam_session_update_path({
    ...PORTALS_REQUEST_URL_PARAMS,
    exam_session_pk: examSessionPk,
  });

  const payload = {
    person_fk: personFk,
    ...body,
  };

  return await makeRequest(url, 'PUT', authToken, payload);
}

export const useUpdateExamSession = (
  authToken: string,
  personFk: number,
  examSessionPk: number
) => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ body }: { body: ExamSessionUpdateParams }) => {
      return await updateExamSession(authToken, personFk, examSessionPk, body);
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['exam', examSessionPk], (oldData: Exam) => ({
          ...oldData,
          ...data,
        }));
        queryClient.invalidateQueries({ queryKey: ['exam', examSessionPk] });
      },
    }
  );
};

export const useUpdateExamCandidateSession = (
  authToken: string,
  personFk: number,
  examSessionPk: number
) => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({
      examCandidateSessionPk,
      body,
    }: {
      examCandidateSessionPk: number;
      body: ExamCandidateSessionUpdateParams;
    }) => {
      return await updateExamCandidateSession(
        authToken,
        examCandidateSessionPk,
        personFk,
        examSessionPk,
        body
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['exam', 'students'] });
      },
    }
  );
};

export const useAddExamSessionIncident = (authToken: string, examSessionPk: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (body: ExamIncident) => {
      return await addExamSessionIncident(authToken, examSessionPk, body);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['exam', 'incidents', examSessionPk] });
      },
    }
  );
};

async function addExamSessionIncident(
  authToken: string,
  examSessionPk: number,
  body: ExamIncident
) {
  const url = Routes.exam_session_add_incident_path({
    ...PORTALS_REQUEST_URL_PARAMS,
    exam_session_pk: examSessionPk,
  });

  return await makeRequest(url, 'POST', authToken, body);
}

export const useDeleteExamSessionIncident = (authToken: string, examSessionPk: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (incidentPk: number) => {
      return await deleteExamSessionIncident(authToken, examSessionPk, incidentPk);
    },
    {
      onMutate: async (incidentPk) => {
        await queryClient.cancelQueries(['exam', 'incidents']);
        const previousIncidents = queryClient.getQueryData(['exam', 'incidents']);
        queryClient.setQueryData(
          ['exam', 'incidents', examSessionPk],
          (oldData: ExamIncident[]) => {
            return oldData.filter((incident) => incident.exam_incident_pk !== incidentPk);
          }
        );
        return { previousIncidents };
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['exam', 'incidents', examSessionPk] });
      },
    }
  );
};

async function deleteExamSessionIncident(
  authToken: string,
  examSessionPk: number,
  incidentPk: number
) {
  const url = Routes.exam_session_delete_incident_path({
    ...PORTALS_REQUEST_URL_PARAMS,
    exam_session_pk: examSessionPk,
    exam_incident_pk: incidentPk,
  });

  return await makeRequest(url, 'DELETE', authToken);
}
