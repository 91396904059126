import React from 'react';
import { ZgOption, DEFAULT_PRIORITY_OPTIONS } from '../types';
import ReactSelect from 'react-select';

type CourseRequestFormSelectProps = {
  field: string;
  options?: ZgOption[];
  selectedValue: number;
  onChange: (field: string, selection: string) => void;
  disabled?: boolean;
  containerWidth?: string;
  menuPlacement?: string;
};

const CourseRequestFormSelect = ({
  field,
  options = DEFAULT_PRIORITY_OPTIONS,
  selectedValue,
  onChange,
  disabled = false,
  containerWidth = '100px',
  menuPlacement = 'auto',
}: CourseRequestFormSelectProps) => {
  const list = options.map((option) => ({
    ...option,
    label: option.description,
  }));
  const selectValue = list.find((option) => option.id === selectedValue);

  const selectStyles = {
    container: (styles) => ({
      ...styles,
      display: 'inline-block',
      height: '20px',
      minHeight: '20px',
      width: containerWidth,
      fontSize: '12px',
    }),
    control: (styles, { isDisabled }) => ({
      ...styles,
      height: '20px',
      minHeight: '20px',
      width: containerWidth,
      border: '1px solid #3b3b3b',
      borderRadius: '2px',
      backgroundColor: isDisabled ? '#f3f4f6' : 'white',
    }),
    indicatorsContainer: (styles) => ({
      ...styles,
      height: '18px',
      paddingTop: '0',
    }),
    valueContainer: (styles) => ({ ...styles, height: '20px', padding: '0 2px' }),
    indicatorSeparator: () => {
      display: 'none';
    },
    dropdownIndicator: (styles) => ({
      ...styles,
      width: '20px',
      padding: '2px',
      color: '#555555',
    }),
    placeholder: (styles) => ({
      ...styles,
      color: 'black',
    }),
    menu: (styles) => ({
      ...styles,
      marginTop: '1px',
      marginBottom: '1px',
      backgroundColor: '#555555',
      color: 'white',
    }),
    option: (styles, { isFocused }) => ({
      ...styles,
      padding: '2px 8px',
      backgroundColor: isFocused ? '#4B92E9' : '#555555',
      color: 'white',
    }),
  };

  return (
    <ReactSelect
      options={list}
      name={field}
      onChange={(value) => onChange(field, value.id)}
      defaultValue={selectValue}
      value={selectValue}
      getOptionValue={(option) => option.id}
      isDisabled={disabled}
      placeholder={list[0].label}
      maxMenuHeight="130px"
      menuPlacement={menuPlacement}
      styles={selectStyles}
      isSearchable={false}
    />
  );
};

export default CourseRequestFormSelect;
