import { useQuery } from '@tanstack/react-query';
import { makeRequest, PORTALS_REQUEST_URL_PARAMS } from '../common/request';
import { Exam, ExamEntry, ExamIncident, ExamStudent } from './types';

export const useExamSessions = (personId: number, authToken: string) =>
  useQuery<Exam[], Error>({
    queryKey: ['exams', 'student'],
    queryFn: () => fetchExamSessions(personId, authToken),
    retry: false,
  });

async function fetchExamSessions(personId: number, authToken: string): Promise<Exam[]> {
  const url = Routes.exam_sessions_path({
    ...PORTALS_REQUEST_URL_PARAMS,
    person_fk: personId,
  });
  return await makeRequest(url, 'GET', authToken);
}

export const useExamSession = (personId: number, authToken: string, examSessionPk?: number) => {
  return useQuery<Exam, Error>({
    queryKey: ['exam', examSessionPk],
    queryFn: () => fetchExamSession(personId, authToken, examSessionPk),
    retry: false,
  });
};

async function fetchExamSession(
  personId: number,
  authToken: string,
  examSessionPk?: number
): Promise<Exam> {
  const url = Routes.exam_session_detail_path({
    ...PORTALS_REQUEST_URL_PARAMS,
    person_fk: personId,
    exam_session_pk: examSessionPk,
  });
  return await makeRequest(url, 'GET', authToken);
}

export const useExamEntryGrades = (personId: number, authToken: string, examId: number) =>
  useQuery<ExamEntry, Error>({
    queryKey: ['exam', 'grades', examId],
    queryFn: () => fetchExamEntryGrades(personId, authToken, examId),
    retry: false,
  });

async function fetchExamEntryGrades(
  personId: number,
  authToken: string,
  examId: number
): Promise<ExamEntry> {
  const url = Routes.exam_session_entry_grades_path({
    ...PORTALS_REQUEST_URL_PARAMS,
    person_fk: personId,
    exam_session_pk: examId,
  });
  return await makeRequest(url, 'GET', authToken);
}

export const useExamSessionStudents = (examSessionPk: number, authToken: string, personFk) =>
  useQuery<ExamStudent[], Error>({
    queryKey: ['exam', 'students', examSessionPk],
    queryFn: () => fetchExamSessionStudents(examSessionPk, authToken, personFk),
    retry: false,
  });

async function fetchExamSessionStudents(
  examSessionPk: number,
  authToken: string,
  personFk: number
): Promise<ExamStudent[]> {
  const url = Routes.exam_session_students_path({
    ...PORTALS_REQUEST_URL_PARAMS,
    exam_session_pk: examSessionPk,
    person_fk: personFk,
  });
  return await makeRequest(url, 'GET', authToken);
}

export const useExamSessionIncidents = (
  authToken: string,
  examSessionPk: number,
  enabled: boolean = false
) =>
  useQuery<ExamIncident[], Error>({
    queryKey: ['exam', 'incidents', examSessionPk],
    queryFn: () => fetchExamSessionIncidents(authToken, examSessionPk),
    retry: false,

    // Exam session incidents are not always needed, so we can disable the query by default
    enabled,
  });

async function fetchExamSessionIncidents(authToken: string, examSessionPk: number) {
  const url = Routes.exam_session_incidents_path({
    ...PORTALS_REQUEST_URL_PARAMS,
    exam_session_pk: examSessionPk,
  });
  return await makeRequest(url, 'GET', authToken);
}
