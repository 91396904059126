import React from 'react';
import moment from 'moment';
import { Exam, PortalType } from './types';
import { formatValue } from './helpers';

type ExamListItemProps = {
  exam: Exam;
  portalType: PortalType;
  onExamClick: (examId: number) => void;
  onShowInCalendar: (date: string) => void;
};

const ExamListItem = ({ exam, portalType, onExamClick, onShowInCalendar }: ExamListItemProps) => {
  const formatDate = (dateStr: string | null | undefined) => {
    if (!dateStr) return '-';
    return moment(dateStr).format('dddd, MMMM Do YYYY');
  };

  return (
    <div className="group">
      <div className="flex text-sm py-3 px-4 hover:bg-gray-50 space-x-4">
        <div
          className="flex-1 text-gray-700 text-left cursor-pointer hover:text-blue-600 underline decoration-gray-300 hover:decoration-blue-600"
          onClick={() => onExamClick(exam.exam_session_fk)}
        >
          {formatValue(exam.exam_session_description)}
        </div>

        <div className="flex-1 text-gray-700 text-left">
          {formatValue(exam.exam_season_description)}
        </div>

        {portalType !== PortalType.Teacher && (
          <div className="flex-1 text-gray-700 text-left">
            {formatValue(exam.qualification_description)}
          </div>
        )}

        <div
          className="flex-[1.5] text-gray-700 text-left cursor-pointer hover:text-blue-600 flex gap-1 underline decoration-gray-300 hover:decoration-blue-600"
          onClick={() => onShowInCalendar(exam.date)}
        >
          {formatDate(exam.date)}
        </div>

        <div className="flex-1 text-gray-600 text-left">
          {exam.start_time_calculated} - {exam.end_time_calculated}
        </div>

        <div className="flex-none text-gray-600 text-right w-24">
          {formatValue(exam.room_description)}
        </div>
      </div>
    </div>
  );
};

export default ExamListItem;
