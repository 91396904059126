import React, { useState } from 'react';
import { useExamSessionStudents } from './queries';
import LoadingSpinner from '../../shared/LoadingSpinner';
import { Exam, ExamStudent } from './types';
import QualificationsDropdown from './QualificationsDropdown';
import StudentListRow from './StudentListRow';
import NoteModal from './NoteModal';

type StudentListProps = {
  examId: number;
  authToken: string;
  exam: Exam;
  filterByQualification: string | null;
  setFilterByQualification: (filter: string | null) => void;
  personId: number;
};

const StudentList = ({
  exam,
  examId,
  authToken,
  filterByQualification,
  setFilterByQualification,
  personId,
}: StudentListProps) => {
  const { data: students, isLoading, error } = useExamSessionStudents(examId, authToken, personId);
  const [selectedStudent, setSelectedStudent] = useState<ExamStudent>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (isLoading || !students) {
    return (
      <div className="flex justify-center p-8">
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center p-8">
        <p>Error loading students list</p>
      </div>
    );
  }

  const filteredStudents = filterByQualification
    ? students.filter((student) => student.qualification_description === filterByQualification)
    : students;

  return (
    <>
      <div className="flex justify-end items-center mb-4">
        <div className="w-64">
          <QualificationsDropdown
            students={students}
            filterByQualification={filterByQualification}
            setFilterByQualification={setFilterByQualification}
          />
        </div>
      </div>
      {students.length === 0 ? (
        <p className="text-center text-gray-500 py-8">No students are scheduled for this session</p>
      ) : (
        <>
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Seat
                </th>
                <th
                  scope="col"
                  className="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Qualification
                </th>
                <th
                  scope="col"
                  className="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Time
                </th>
                <th
                  scope="col"
                  className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                >
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {filteredStudents.map((student: ExamStudent) => (
                <StudentListRow
                  key={student.exam_candidate_session_pk}
                  student={student}
                  exam={exam}
                  authToken={authToken}
                  personId={personId}
                  onStudentSelect={(student) => {
                    setSelectedStudent(student);
                    setIsModalOpen(true);
                  }}
                />
              ))}
            </tbody>
          </table>
        </>
      )}
      <NoteModal
        authToken={authToken}
        personId={personId}
        exam={exam}
        selectedStudent={selectedStudent}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
      />
    </>
  );
};

export default StudentList;
