import { ErrorMessage, Field, Form, Formik } from 'formik';
import Modal, { ModalProps } from '../../shared/Modal';
import React from 'react';
import { Exam, ExamStudent } from './types';
import { useUpdateExamCandidateSession } from './mutations';
import * as Yup from 'yup';
import { TIME_REGEX } from './constants';

const VALIDATION_SCHEMA = Yup.object().shape({
  actual_start_time: Yup.string().matches(TIME_REGEX, 'Invalid time format'),
  actual_end_time: Yup.string().matches(TIME_REGEX, 'Invalid time format'),
  notes: Yup.string(),
});

type NoteModalProps = Pick<ModalProps, 'isOpen' | 'setIsOpen' | 'onSave'> & {
  authToken: string;
  personId: number;
  exam: Exam;
  selectedStudent: ExamStudent;
};

const NoteModal = ({
  authToken,
  personId,
  exam,
  selectedStudent,
  isOpen,
  setIsOpen,
}: NoteModalProps) => {
  const updateExamCandidateSession = useUpdateExamCandidateSession(
    authToken,
    personId,
    exam.exam_session_fk
  );
  const initialValues = {
    actual_start_time: selectedStudent?.actual_start_time_override || '',
    actual_end_time: selectedStudent?.actual_end_time_override || '',
    notes: selectedStudent?.notes || '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={VALIDATION_SCHEMA}
      enableReinitialize={true}
      onSubmit={(student) => {
        updateExamCandidateSession.mutate(
          {
            examCandidateSessionPk: selectedStudent.exam_candidate_session_pk,
            body: student,
          },
          { onSuccess: () => setIsOpen(false) }
        );
      }}
    >
      {({ handleSubmit, isSubmitting, isValid }) => (
        <Modal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          isSubmitDisabled={updateExamCandidateSession.isLoading || isSubmitting || !isValid}
          onSave={handleSubmit}
          label="Save"
        >
          <Form>
            <div className="flex items-center">
              <img
                alt={selectedStudent?.first_name}
                src={selectedStudent?.photo_url}
                className="inline-block h-12 w-12 rounded-full"
              />
              <div className="ml-3">
                <h2 className="text-base/6 font-semibold text-gray-900">
                  {`${selectedStudent?.first_name} ${selectedStudent?.last_name}`}
                </h2>
                <p className="text-sm/6 text-gray-600">
                  {selectedStudent?.qualification_description}
                </p>
                {selectedStudent?.components.length > 0 && (
                  <details>
                    <summary className="text-sm/6 text-gray-600 hover:underline cursor-pointer">
                      See components
                    </summary>
                    <div className="text-sm/6 text-gray-600">
                      {selectedStudent?.components.map((c) => c.description).join(', ')}
                    </div>
                  </details>
                )}
              </div>
            </div>
            <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 mt-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="actual-start-time"
                  className="block text-sm/6 font-medium text-gray-900"
                >
                  Actual Start Time
                </label>
                <div className="mt-2">
                  <Field
                    id="actual-start-time"
                    name="actual_start_time"
                    type="text"
                    placeholder={
                      selectedStudent?.start_time_override || exam?.start_time_calculated
                    }
                    disabled={updateExamCandidateSession.isLoading}
                    className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-blue-600 sm:text-sm/6"
                  />
                  <ErrorMessage
                    name="actual_start_time"
                    component="div"
                    className="mt-2 text-sm text-red-600"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="actual-end-time"
                  className="block text-sm/6 font-medium text-gray-900"
                >
                  Actual End Time
                </label>
                <div className="mt-2">
                  <Field
                    id="actual-end-time"
                    name="actual_end_time"
                    type="text"
                    placeholder={selectedStudent?.end_time_override || exam?.end_time_calculated}
                    disabled={updateExamCandidateSession.isLoading}
                    className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-blue-600 sm:text-sm/6"
                  />
                  <ErrorMessage
                    name="actual_end_time"
                    component="div"
                    className="mt-2 text-sm text-red-600"
                  />
                </div>
              </div>
              <div className="col-span-full">
                <label
                  htmlFor="notes"
                  className="block text-sm/6 font-medium text-gray-900"
                >
                  Notes
                </label>
                <div className="mt-2">
                  <Field
                    as="textarea"
                    id="notes"
                    name="notes"
                    rows={3}
                    className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-blue-600 sm:text-sm/6"
                    disabled={updateExamCandidateSession.isLoading}
                  />
                </div>
              </div>
            </div>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default NoteModal;
