import React from 'react';
import Select from 'react-select';
import { ExamStudent } from './types';

type QualificationsDropdownProps = {
  students: ExamStudent[];
  filterByQualification: string | null;
  setFilterByQualification: (filter: string | null) => void;
};

const QualificationsDropdown = ({
  students,
  filterByQualification,
  setFilterByQualification,
}: QualificationsDropdownProps) => {
  const qualifications = React.useMemo(() => {
    if (!students) return [];
    return Array.from(new Set(students.map((s) => s.qualification_description))).sort();
  }, [students]);

  const qualificationOptions = React.useMemo(() => {
    return [
      { value: '', label: 'All Qualifications' },
      ...qualifications.map((qual) => ({ value: qual, label: qual })),
    ];
  }, [qualifications]);

  return (
    <Select
      value={
        qualificationOptions.find((option) => option.value === filterByQualification) ||
        qualificationOptions[0]
      }
      onChange={(option) => setFilterByQualification(option?.value || null)}
      options={qualificationOptions}
      isClearable={false}
      className="text-sm"
      classNamePrefix="react-select"
      isSearchable={false}
    />
  );
};

export default QualificationsDropdown;
