import React from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Exam } from './types';
import classNames from 'classnames';

interface ExamCalendarWeekDayProps {
  date: string;
  dayExams: Exam[];
  isToday: boolean;
  maxExamsPerDay: number;
  expandedDays: boolean;
  setExpandedDays: (expanded: boolean) => void;
  isHighlighted: boolean;
}

const ExamCalendarWeekDay: React.FC<ExamCalendarWeekDayProps> = ({
  date,
  dayExams,
  isToday,
  maxExamsPerDay,
  expandedDays,
  setExpandedDays,
  isHighlighted,
}) => {
  const navigate = useNavigate();

  const handleExamClick = (examId: number, event: React.MouseEvent) => {
    event.stopPropagation();
    navigate(`/exam-session/${examId}`);
  };

  return (
    <div
      className={classNames('border rounded-lg p-4 min-h-[150px] bg-white', {
        'ring-2 ring-blue-400 ring-offset-2': isToday,
        'ring-2 ring-yellow-400 ring-opacity-50': isHighlighted,
      })}
    >
      <div className="text-sm font-semibold mb-2">
        <div>{moment(date).format('dddd')}</div>
        <div className="text-gray-500">{moment(date).format('MMMM D')}</div>
      </div>

      <div className="space-y-2">
        {dayExams.map((exam, index) => {
          if (expandedDays || index < maxExamsPerDay) {
            return (
              <div
                key={exam.exam_session_fk + index.toString()}
                className="text-sm p-2 bg-blue-50 rounded-md hover:bg-blue-100 cursor-pointer transition-colors"
                onClick={(e) => handleExamClick(exam.exam_session_fk, e)}
              >
                <div className="font-medium">{exam.exam_session_description}</div>
                <div className="text-xs text-gray-600">
                  {exam.start_time_calculated} - {exam.end_time_calculated}
                </div>
              </div>
            );
          }

          if (index === maxExamsPerDay) {
            return (
              <div
                key={exam.exam_session_fk + index.toString()}
                className="text-sm p-1 bg-blue-50 rounded-md cursor-pointer hover:bg-blue-100"
                onClick={() => setExpandedDays(true)}
              >
                <div className="font-medium">+ {dayExams.length - maxExamsPerDay} more</div>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default ExamCalendarWeekDay;
