import React from 'react';
import { Route, Routes, unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ExamDetail from './ExamDetail';
import { createBrowserHistory } from 'history';
import { getBasePath } from './helpers';
import moment from 'moment';
import LandingPage from './LandingPage';
import { PortalType } from './types';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 200000,
    },
  },
});

const history = createBrowserHistory();

type ExamsRouterProps = {
  personId: number;
  authToken: string;
  today: string;
  showGrades?: boolean;
  portalType: PortalType;
};

const ExamsRouter = ({
  personId,
  authToken,
  today,
  showGrades = false,
  portalType = PortalType.Student,
}: ExamsRouterProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      <HistoryRouter
        history={history}
        basename={getBasePath()}
      >
        <Routes>
          <Route
            path="/"
            element={
              <LandingPage
                personId={personId}
                authToken={authToken}
                today={moment(today).format('YYYY-MM-DD')}
                portalType={portalType}
              />
            }
          />
          <Route
            path="/exam-session/:examId"
            element={
              <ExamDetail
                personId={personId}
                authToken={authToken}
                showGrades={showGrades}
                portalType={portalType}
              />
            }
          />
        </Routes>
      </HistoryRouter>
    </QueryClientProvider>
  );
};

export default ExamsRouter;
