import React, { useState } from 'react';
import classNames from 'classnames';
import { AttendanceStatus, Exam, ExamStudent } from './types';
import { useUpdateExamCandidateSession } from './mutations';

const attendanceStatusLabel = (student: ExamStudent) => {
  switch (student.attendance_status) {
    case AttendanceStatus.Present:
      return 'Mark as Absent';
    case AttendanceStatus.Absent:
      return 'Mark as Present';
    case AttendanceStatus.Offsite:
    default:
      return 'Student is offsite. You cannot change their attendance status.';
  }
};

type StudentListRowProps = {
  student: ExamStudent;
  exam: Exam;
  authToken: string;
  personId: number;
  onStudentSelect: (student: ExamStudent) => void;
};

const StudentListRow = ({
  student,
  exam,
  authToken,
  personId,
  onStudentSelect,
}: StudentListRowProps) => {
  const [rowIsExpanded, setRowIsExpanded] = useState(false);
  const updateExamCandidateSession = useUpdateExamCandidateSession(
    authToken,
    personId,
    exam.exam_session_fk
  );

  const isStartTimeOverride = (student: ExamStudent) =>
    student.start_time_override && student.start_time_override !== exam.start_time_calculated;

  const isEndTimeOverride = (student: ExamStudent) =>
    student.end_time_override && student.end_time_override !== exam.end_time_calculated;

  const seatNumberDisplay = (seatNumber: string) => {
    if (!seatNumber || seatNumber === '<None>') {
      return '—';
    }
    return seatNumber;
  };

  const toggleAttendanceStatus = async () => {
    await updateExamCandidateSession.mutateAsync({
      examCandidateSessionPk: student.exam_candidate_session_pk,
      body: {
        attendance_status:
          student.attendance_status === AttendanceStatus.Present
            ? AttendanceStatus.Absent
            : AttendanceStatus.Present,
      },
    });
  };

  const notesIconClass = classNames('vx-tooltipped vx-tooltipped--w text-gray-300', {
    'text-blue-500':
      student.notes || student.actual_start_time_override || student.actual_end_time_override,
  });

  return (
    <tr>
      <td className="w-full max-w-0 py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0">
        <div className={'flex items-center gap-x-2'}>
          <img
            alt=""
            src={student.photo_url}
            className="w-8 h-8 rounded-full bg-gray-800"
          />
          <div>{`${student.first_name} ${student.last_name}`}</div>
        </div>
        <dl className="font-normal sm:hidden">
          <dt className="sr-only">Title</dt>
          <dd className="mt-1 truncate text-gray-700">
            Seat: {seatNumberDisplay(student.seat_number)}
          </dd>
          <dt className="sr-only sm:hidden">Email</dt>
          <dd className="mt-1 truncate text-gray-500 sm:hidden">
            {student.qualification_description}
          </dd>
          <dt className="sr-only sm:hidden">Time</dt>
          <dd className="mt-1 truncate text-gray-500 sm:hidden">
            <span className={classNames({ 'font-bold': isStartTimeOverride(student) })}>
              {student.start_time_override || exam.start_time_calculated}
            </span>
            &nbsp;&mdash;&nbsp;
            <span className={classNames({ 'font-bold': isEndTimeOverride(student) })}>
              {student.end_time_override || exam.end_time_calculated}
            </span>
          </dd>
        </dl>
      </td>
      <td className="hidden sm:table-cell px-3 py-2 text-sm text-gray-500">
        {seatNumberDisplay(student.seat_number)}
      </td>
      <td className="hidden sm:table-cell px-3 py-2 text-sm text-gray-500">
        <div className={classNames('truncate', { truncate: !rowIsExpanded })}>
          {student.qualification_description}
        </div>
      </td>
      <td className="hidden sm:table-cell px-3 py-2 text-sm text-gray-500">
        <span className={classNames({ 'font-bold': isStartTimeOverride(student) })}>
          {student.start_time_override || exam.start_time_calculated}
        </span>
        &nbsp;&mdash;&nbsp;
        <span className={classNames({ 'font-bold': isEndTimeOverride(student) })}>
          {student.end_time_override || exam.end_time_calculated}
        </span>
      </td>
      <td className="py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-0 align-top">
        <span className="isolate inline-flex rounded-md shadow-sm">
          <button
            type="button"
            className={`${notesIconClass} relative inline-flex items-center rounded-l-md bg-white px-3 py-1 text-sm text-gray-500 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10`}
            aria-label="View/Edit Candidate Detail"
            onClick={() => onStudentSelect(student)}
          >
            <i className={'nc-icon-glyph business_notes align-middle'} />
          </button>
          <button
            type="button"
            className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-1 text-sm text-gray-500 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 vx-tooltipped vx-tooltipped--w"
            onClick={toggleAttendanceStatus}
            disabled={
              updateExamCandidateSession.isLoading ||
              student.attendance_status === AttendanceStatus.Offsite
            }
            aria-label={attendanceStatusLabel(student)}
          >
            <i
              className={classNames('nc-icon-mini align-middle', {
                'ui-1_check': student.attendance_status === AttendanceStatus.Present,
                'users_delete-28 text-red-500':
                  student.attendance_status === AttendanceStatus.Absent,
                'location_appointment text-gray-300 cursor-not-allowed':
                  student.attendance_status === AttendanceStatus.Offsite,
              })}
            />
            {updateExamCandidateSession.isLoading && (
              <div className="flex space-x-1 absolute right-0.5">
                <div className="w-1 h-1 bg-gray-500 rounded-full animate-[bounce_0.7s_infinite]" />
                <div className="w-1 h-1 bg-gray-500 rounded-full animate-[bounce_0.7s_0.2s_infinite]" />
                <div className="w-1 h-1 bg-gray-500 rounded-full animate-[bounce_0.7s_0.4s_infinite]" />
              </div>
            )}
          </button>
        </span>
      </td>
    </tr>
  );
};

export default StudentListRow;
