import React, { useState, useMemo, useEffect, SetStateAction, Dispatch } from 'react';
import moment from 'moment';
import { Exam } from './types';
import classNames from 'classnames';
import ExamCalendarWeekDay from './ExamCalendarWeekDay';
import {
  getTodayExamsCount,
  getTomorrowExamsCount,
  getWeekExamsCount,
  isCurrentWeekCheck,
  isFutureWeekCheck,
  calculateWeekDays,
} from './helpers';

interface BackToTodayProps {
  onClick: () => void;
}

const BackToToday: React.FC<BackToTodayProps> = ({ onClick }) => (
  <button
    onClick={onClick}
    className="px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-md text-sm"
  >
    Back to Today
  </button>
);

interface ExamCalendarProps {
  exams: Exam[];
  today: string;
  selectedWeekStart?: string;
  highlightedDate?: string;
  setSelectedWeekStart: Dispatch<SetStateAction<string>>;
}

const ExamCalendar: React.FC<ExamCalendarProps> = ({
  exams,
  today,
  selectedWeekStart: currentWeekStart,
  highlightedDate,
  setSelectedWeekStart: setCurrentWeekStart,
}) => {
  const [expandedDays, setExpandedDays] = useState(false);
  const maxExamsPerDay = 2;

  const navigateWeek = (direction: 'prev' | 'next') => {
    setExpandedDays(false);
    setCurrentWeekStart((prev: string) =>
      direction === 'next'
        ? moment(prev).add(1, 'week').format('YYYY-MM-DD')
        : moment(prev).subtract(1, 'week').format('YYYY-MM-DD')
    );
  };

  const weekDays = useMemo(() => {
    return calculateWeekDays(moment(currentWeekStart), exams);
  }, [currentWeekStart, exams]);

  const weekExamsCount = useMemo(() => {
    return getWeekExamsCount(weekDays);
  }, [weekDays]);

  const todayExamsCount = useMemo(() => {
    return getTodayExamsCount(exams, today);
  }, [exams, today]);

  const tomorrowExamsCount = useMemo(() => {
    return getTomorrowExamsCount(exams, today);
  }, [exams, today]);

  const isCurrentWeek = useMemo(() => {
    return isCurrentWeekCheck(weekDays, today);
  }, [weekDays, today]);

  const isFutureWeek = useMemo(() => {
    return isFutureWeekCheck(weekDays, today, isCurrentWeek);
  }, [weekDays, today, isCurrentWeek]);

  const goToToday = () => {
    setExpandedDays(false);
    setCurrentWeekStart(moment(today).startOf('isoWeek').format('YYYY-MM-DD'));
  };

  let message = '';
  const setExamMessage = (count: number, when: string) => {
    message = `(${count} exam${count !== 1 ? 's' : ''} ${when})`;
  };

  if (isCurrentWeek) {
    if (todayExamsCount > 0) {
      setExamMessage(todayExamsCount, 'today');
    } else if (tomorrowExamsCount > 0) {
      setExamMessage(tomorrowExamsCount, 'tomorrow');
    } else if (weekExamsCount > 0) {
      setExamMessage(weekExamsCount, 'this week');
    }
  }

  if (isFutureWeek && weekExamsCount > 0) {
    setExamMessage(weekExamsCount, 'scheduled');
  }

  const messageClasses = classNames('text-gray-500 ml-2', {
    'text-red-3': isCurrentWeek && message,
  });

  return (
    <div className="space-y-4 px-6">
      <div className="flex justify-between items-center">
        <div className="w-[240px] flex justify-start gap-2">
          <button
            onClick={() => navigateWeek('prev')}
            className="px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-md text-sm"
          >
            Previous Week
          </button>
          {!isCurrentWeek && isFutureWeek && <BackToToday onClick={goToToday} />}
        </div>
        <span className="font-semibold text-center">
          {moment(currentWeekStart).format('MMMM D')} -{' '}
          {moment(currentWeekStart).add(6, 'days').format('MMMM D, YYYY')}
          {message && <span className={messageClasses}>{message}</span>}
        </span>
        <div className="w-[240px] flex justify-end gap-2">
          {!isCurrentWeek && !isFutureWeek && <BackToToday onClick={goToToday} />}
          <button
            onClick={() => navigateWeek('next')}
            className="px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-md text-sm"
          >
            Next Week
          </button>
        </div>
      </div>

      <div className="grid grid-cols-7 gap-2">
        {weekDays.map(({ date, exams: dayExams }) => (
          <ExamCalendarWeekDay
            date={date}
            dayExams={dayExams}
            isToday={date === today}
            maxExamsPerDay={maxExamsPerDay}
            expandedDays={expandedDays}
            setExpandedDays={setExpandedDays}
            key={date}
            isHighlighted={highlightedDate === date}
          />
        ))}
      </div>
    </div>
  );
};

export default ExamCalendar;
