import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Exam, PortalType } from './types';
import ExamListItem from './ExamListItem';

type ExamSessionListProps = {
  exams: Exam[];
  onShowInCalendar: (date: string) => void;
  portalType: PortalType;
};

const ExamSessionList = ({ exams, onShowInCalendar, portalType }: ExamSessionListProps) => {
  const navigate = useNavigate();

  const handleExamClick = (examId: number) => {
    navigate(`/exam-session/${examId}`);
  };

  return (
    <div className="space-y-4">
      <div className="shadow-md rounded-md overflow-hidden">
        {exams.length === 0 ? (
          <div className="bg-white p-8 text-center text-gray-500">No exams found</div>
        ) : (
          <>
            <div className="flex bg-gray-100 text-sm font-bold text-gray-800 py-3 px-4 space-x-4">
              <div className="flex-1 text-left">Session</div>
              <div className="flex-1 text-left">Season</div>
              {portalType !== PortalType.Teacher && (
                <div className="flex-1 text-left">Qualification</div>
              )}
              <div className="flex-[1.5] text-left">Date</div>
              <div className="flex-1 text-left">Time</div>
              <div className="flex-none text-right w-24">Room</div>
            </div>

            <div className="bg-white divide-y divide-gray-200">
              {exams.map((exam, index) => (
                <ExamListItem
                  key={exam.exam_session_fk + index.toString()}
                  exam={exam}
                  portalType={portalType}
                  onExamClick={handleExamClick}
                  onShowInCalendar={onShowInCalendar}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ExamSessionList;
