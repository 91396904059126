import Modal from '../../shared/Modal';
import React, { useEffect } from 'react';
import { useExamSessionIncidents } from './queries';
import LoadingSpinner from '../../shared/LoadingSpinner';
import classNames from 'classnames';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { TIME_REGEX } from './constants';
import { useAddExamSessionIncident, useDeleteExamSessionIncident } from './mutations';
import { ExamIncident } from './types';

const VALIDATION_SCHEMA = Yup.object().shape({
  description: Yup.string().required('Description is required'),
  incident_time: Yup.string()
    .required('Time is required')
    .matches(TIME_REGEX, 'Invalid time format'),
  notes: Yup.string(),
});

type IncidentModalProps = {
  authToken: string;
  examId: number;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const IncidentModal = ({ authToken, examId, isOpen, setIsOpen }: IncidentModalProps) => {
  const { data: incidents, isLoading, error } = useExamSessionIncidents(authToken, examId, isOpen);
  const addExamSessionIncident = useAddExamSessionIncident(authToken, examId);
  const deleteExamSessionIncident = useDeleteExamSessionIncident(authToken, examId);
  const currentTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  const initialValues = {
    description: '',
    incident_time: currentTime,
    notes: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={VALIDATION_SCHEMA}
      enableReinitialize={true}
      onSubmit={(incident: ExamIncident, { resetForm }) => {
        addExamSessionIncident.mutate(incident, {
          onSuccess: () => {
            resetForm();
          },
        });
      }}
    >
      {({ handleSubmit, isSubmitting, isValid }) => (
        <Modal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          isSubmitDisabled={!isValid || isSubmitting}
          onSave={handleSubmit}
          cancelLabel={'Close'}
          label="Add Incident"
        >
          <h2 className="text-base/6 font-semibold text-gray-900 mb-4">Session Incidents</h2>

          {isLoading && (
            <div className="flex justify-center">
              <div className="vx-loader-spinner py-10"></div>
            </div>
          )}

          {error && (
            <div className="p-4 text-center text-red-600">
              <i className="nc-icon-glyph ui-2_alert-circle-i pr-2" />
              Error loading incidents
            </div>
          )}

          {incidents?.length === 0 ? (
            <div className="text-center my-6">
              <i className="text-gray-500 nc-icon-glyph text-4xl files_archive"></i>
              <h3 className="mt-2 text-sm font-semibold text-gray-900">No incidents</h3>
              <p className="mt-1 text-sm text-gray-500">Get started by creating an incident</p>
            </div>
          ) : (
            <ul role="list">
              {incidents?.map((incident, incidentIdx) => (
                <li
                  key={incident.exam_incident_pk}
                  className="relative flex gap-x-4 mb-2"
                >
                  <div
                    className={classNames('absolute left-0 top-0 flex w-6 justify-center', {
                      'h-6': incidentIdx === incidents.length - 1,
                      '-bottom-6': incidentIdx !== incidents.length - 1,
                    })}
                  >
                    <div className="w-px bg-gray-200" />
                  </div>
                  <>
                    <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                      <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300"></div>
                    </div>

                    <div className="flex-auto">
                      <div className="flex justify-between gap-x-4">
                        <div className="py-0.5 text-xs/5 text-gray-500">
                          <span className="font-medium text-gray-900">{incident.description}</span>
                        </div>
                        <time
                          dateTime={incident.incident_time}
                          className="flex items-center py-0.5 text-xs/5 text-gray-500"
                        >
                          {incident.incident_time}
                          <i
                            className="text-gray-500 nc-icon-mini ui-1_trash ml-1 cursor-pointer"
                            onClick={() => {
                              deleteExamSessionIncident.mutate(incident.exam_incident_pk);
                            }}
                          ></i>
                        </time>
                      </div>
                      <p className="text-sm/6 text-gray-500">{incident.notes}</p>
                    </div>
                  </>
                </li>
              ))}
            </ul>
          )}

          <div className="relative mt-6">
            <div
              aria-hidden="true"
              className="absolute inset-0 flex items-center"
            >
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center">
              <span className="bg-white px-2 text-sm text-gray-500">Add an incident</span>
            </div>
          </div>

          <Form className="flex justify-center">
            <div className="grid gap-x-6 gap-y-4 grid-cols-6 mt-6 max-w-2xl">
              <div className="col-span-4">
                <label
                  htmlFor="description"
                  className="block text-sm/6 font-bold text-gray-900"
                >
                  Description
                </label>
                <div className="mt-2">
                  <Field
                    id="description"
                    name="description"
                    type="text"
                    className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-blue-600 sm:text-sm/6"
                  />
                </div>
              </div>
              <div className="col-span-2">
                <label
                  htmlFor="incident_time"
                  className="block text-sm/6 font-bold text-gray-900"
                >
                  Time
                </label>
                <div className="mt-2">
                  <Field
                    id="incident_time"
                    name="incident_time"
                    type="text"
                    onFocus={(e) => e.target.select()}
                    className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-blue-600 sm:text-sm/6"
                  />
                  <ErrorMessage
                    name="incident_time"
                    component="div"
                    className="mt-2 text-sm text-red-600"
                  />
                </div>
              </div>
              <div className="col-span-6">
                <label
                  htmlFor="notes"
                  className="block text-sm/6 font-medium text-gray-900"
                >
                  Notes
                </label>
                <div className="mt-2">
                  <Field
                    as="textarea"
                    id="notes"
                    name="notes"
                    rows={2}
                    className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-blue-600 sm:text-sm/6"
                  />
                </div>
              </div>
            </div>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default IncidentModal;
