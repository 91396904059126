import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useExamSession, useExamSessionIncidents } from './queries';
import { formatValue } from './helpers';
import LoadingSpinner from '../../shared/LoadingSpinner';
import moment from 'moment';
import ExamGrades from './ExamGrades';
import StudentList from './StudentList';
import { PortalType } from './types';
import { useUpdateExamSession } from './mutations';
import classNames from 'classnames';
import IncidentModal from './IncidentModal';

type ExamDetailProps = {
  personId: number;
  authToken: string;
  showGrades: boolean;
  portalType: PortalType;
};

const ExamDetail = ({ personId, authToken, showGrades, portalType }: ExamDetailProps) => {
  const { examId } = useParams();
  const examIdNumber = parseInt(examId);
  const [filterByQualification, setFilterByQualification] = useState<string | null>(null);
  const { data: exam, isLoading, error } = useExamSession(personId, authToken, examIdNumber);
  const updateExamSession = useUpdateExamSession(authToken, personId, examIdNumber);
  const [isIncidentModalOpen, setIsIncidentModalOpen] = useState(false);

  if (isLoading) {
    return (
      <div className="flex justify-center p-8">
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 text-center text-red-600">
        <i className="nc-icon-glyph ui-2_alert-circle-i pr-2" />
        Error loading exam details
      </div>
    );
  }

  if (!exam || !Object.keys(exam).length) {
    return (
      <div className="p-4 text-center text-xl">
        <i className="nc-icon-glyph design_window-dev pr-2" />
        No exam details found
      </div>
    );
  }

  const formatDate = (dateStr: string) => {
    if (!dateStr) return '-';
    return moment(dateStr).format('dddd, MMMM Do YYYY');
  };

  return (
    <div className="max-w-4xl mx-auto p-4">
      <div className="mb-8">
        <div className="mb-2">
          <Link
            className="font-medium text-gray-600 hover:text-gray-800"
            to={'/'}
          >
            <i className="nc-icon-glyph arrows-1_minimal-left pr-2" />
            Back to Exams
          </Link>
        </div>
        <div className="mb-4 lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="text-2xl/7 font-bold text-gray-800 sm:truncate sm:text-3xl sm:tracking-tight">
              {exam.exam_session_description}
            </h2>
            <div className="flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <i className="nc-icon-mini ui-1_calendar-57 mr-1.5 text-gray-400" />
                {formatDate(exam.date)}
              </div>
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <i className="nc-icon-mini ui-2_time-clock mr-1.5 text-gray-400" />
                {exam.start_time_calculated} to {exam.end_time_calculated}
              </div>
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <i className="nc-icon-mini location_pin mr-1.5 text-gray-400" />
                {formatValue(exam.room_description)}
              </div>
            </div>
          </div>
          {portalType === PortalType.Teacher && (
            <div className="mt-5 flex lg:ml-4 lg:mt-0">
              {exam.is_lead && (
                <button
                  type="button"
                  className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  onClick={() => setIsIncidentModalOpen(true)}
                >
                  <i className="nc-icon-mini location_flag-points-32 -ml-0.5 mr-1.5 text-gray-400" />
                  Log Incident
                </button>
              )}

              <button
                type="button"
                className={classNames(
                  'ml-3 inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2',
                  {
                    'text-white bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600 shadow-sm ':
                      exam.attendance_taken_on === null,
                    'text-green-800 bg-green-100 cursor-not-allowed':
                      exam.attendance_taken_on !== null,
                  }
                )}
                onClick={() =>
                  updateExamSession.mutate({
                    body: {
                      // The date specified here is irrelevant. The server will always use the client's local time.
                      attendance_taken_on: new Date(),
                    },
                  })
                }
                disabled={updateExamSession.isLoading || exam.attendance_taken_on !== null}
              >
                <i
                  className={classNames('nc-icon-mini mr-1.5', {
                    'ui-1_pencil': exam.attendance_taken_on === null,
                    'ui-1_check-simple': exam.attendance_taken_on !== null,
                  })}
                />
                {updateExamSession.isLoading
                  ? 'Submitting...'
                  : exam.attendance_taken_on === null
                  ? 'Complete Attendance'
                  : `Taken ${moment(exam.attendance_taken_on).format('MMM D, YYYY h:mm A')}`}
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="overflow-hidden min-h-[400px]">
        <>
          {portalType !== PortalType.Teacher && (
            <div className="px-6 py-4">
              <h2 className="text-lg font-semibold text-gray-800 mb-4">Exam Details</h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                  <p className="text-sm text-gray-600 mb-1">Qualification</p>
                  <p className="font-medium">{formatValue(exam.qualification_description)}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-600 mb-1">Season</p>
                  <p className="font-medium">{formatValue(exam.exam_season_description)}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-600 mb-1">Awarding Body</p>
                  <p className="font-medium">{formatValue(exam.awarding_body_description)}</p>
                </div>
              </div>
            </div>
          )}

          {portalType === PortalType.Teacher && (
            <StudentList
              exam={exam}
              examId={examIdNumber}
              authToken={authToken}
              filterByQualification={filterByQualification}
              setFilterByQualification={setFilterByQualification}
              personId={personId}
            />
          )}

          {showGrades && portalType !== PortalType.Parent && (
            <ExamGrades
              personId={personId}
              authToken={authToken}
              examId={examIdNumber}
            />
          )}

          {portalType === PortalType.Teacher && exam.is_lead && (
            <IncidentModal
              authToken={authToken}
              examId={examIdNumber}
              isOpen={isIncidentModalOpen}
              setIsOpen={setIsIncidentModalOpen}
            />
          )}
        </>
      </div>
    </div>
  );
};

export default ExamDetail;
