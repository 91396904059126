import React from 'react';
import { useStudentState } from '../../../../stores/useCourseRequestsStore';
import { CurrentRequestData, PortalConfigValues, REQUEST_STATUS } from '../types';
import { TOOLTIP_CLASSES } from '../constants';
import { getLabels } from '../helpers';

type RequestApprovalProps = {
  courseRequest: CurrentRequestData;
  requestPending: boolean;
  requestApproved: boolean;
  approvalsNeeded: string;
  approvalReadOnly: boolean;
  portalConfig: PortalConfigValues;
  handleRemoveRequest: (number) => void;
};

const StatusIcon = ({
  courseRequest,
  requestPending,
  requestApproved,
  approvalsNeeded,
  approvalReadOnly,
  portalConfig,
  handleRemoveRequest,
}: RequestApprovalProps) => {
  const { isFacultyPortal, readOnly: readOnlyPortalStatus } = useStudentState();
  const requestDenied: boolean = courseRequest.approval_status === REQUEST_STATUS.Denied;
  const readOnly: boolean = readOnlyPortalStatus || (!isFacultyPortal && requestApproved);
  const canDeleteRequest =
    !readOnly &&
    !requestApproved &&
    !requestDenied &&
    !requestPending &&
    (isFacultyPortal ? !approvalReadOnly : !courseRequest.recommended);
  const overrideLabeledApprovalsNeeded = (
    approvalsNeeded: string,
    portalConfig: PortalConfigValues
  ): string => {
    return approvalsNeeded
      ?.replace('advisor', getLabels(portalConfig)['advisor'])
      ?.replace('homeroom teacher', getLabels(portalConfig)['homeroom'])
      ?.replace('teacher', getLabels(portalConfig)['teacher']);
  };

  return (
    <div className="w-5 ml-2.5">
      {requestApproved && (
        <div className="group relative cursor-help w-5">
          <i
            className="nc-icon-glyph ui-1_check-curve text-green-4 text-xl"
            data-testid="request-approved"
          ></i>
          <span className={`${TOOLTIP_CLASSES} right-8`}>Course Request Approved!</span>
        </div>
      )}
      {requestPending && (
        <div className="group relative cursor-help w-5">
          <i
            className="nc-icon-glyph ui-2_time-countdown text-neutral-3 text-xl"
            data-testid="request-pending"
          ></i>
          <span className={`${TOOLTIP_CLASSES} right-8`}>
            {isFacultyPortal
              ? `Pending Approvals from: ${overrideLabeledApprovalsNeeded(
                  approvalsNeeded,
                  portalConfig
                )}`
              : 'Pending Additional Approvals'}
          </span>
        </div>
      )}
      {requestDenied && (
        <div className="group relative cursor-help w-5">
          <i
            className="nc-icon-glyph ui-2_ban text-neutral-3 text-xl"
            data-testid="request-denied"
          ></i>
          <span className={`${TOOLTIP_CLASSES} right-8`}>Course Request Denied</span>
        </div>
      )}
      {canDeleteRequest && (
        <button
          onClick={() => handleRemoveRequest(courseRequest.id)}
          disabled={readOnly}
          className="text-red-4"
        >
          <i className="nc-icon-glyph ui-1_trash text-xl leading-7"></i>
        </button>
      )}
    </div>
  );
};

export default StatusIcon;
