import { CourseRequestFormData } from './types';

export const DEFAULT_COURSE_REQUEST_DATA: CourseRequestFormData = {
  course: 0,
  priority: 0,
  grading_period: 50,
  enrollment_level: 0,
  approval_status: 0,
  recommended: false,
  notes: '',
};

export const ACCESS_STATUS_CONFIG_KEY = 'enabled';

export const CLOSE_MODAL_ANIMATION_DURATION = 300;
export const FAKE_LOADING_DURATION = 500;

export const CREDITS_HELP_TEXT =
  'Credits shown here are a calculation of the typical credits awarded based on prior year class data.';

export const FIELD_CONFIGS = [
  'show_term',
  'show_level',
  'show_approved',
  'show_recommended',
  'show_notes',
];

export const GRADES_PERMISSION_HELP_TEXT =
  'Grades may not be viewable for some classes based on view grades permissions';

export const TOOLTIP_CLASSES =
  'invisible group-hover:visible absolute -top-2 right-[52px] w-[88px] bg-[#000000CC] rounded-sm after:absolute after:top-[32%] after:-right-3.5 after:border-8 after:border-l-[#000000CC] after:border-y-transparent after:border-r-transparent text-white text-left text-xs font-normal p-1';
