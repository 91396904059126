import React, { useEffect, useState } from 'react';
import { useCourseRequestState, useStudentState } from '../../../../stores/useCourseRequestsStore';
import classNames from 'classnames';
import CourseRequestFormSelect from '../shared/CourseRequestFormSelect';
import CheckBox from '../shared/Checkbox';
import {
  hasAnyApprovals,
  showField,
  userApprovedRequest,
  readOnlyApprovalField,
  approvedWithoutUserApproval,
} from '../helpers';
import { useFetch } from '../../../../hooks/useFetch';
import { handleUpdateRequest } from '../resources';
import NotesTextarea from './NotesTextarea';
import { CurrentRequestData, REQUEST_STATUS } from '../types';
import StatusIcon from '../shared/StatusIcon';
import { TOOLTIP_CLASSES } from '../constants';

type RequestCardBodyProps = {
  requestProps: CurrentRequestData;
  index: number;
  totalRequests: number;
  setModalRemovingCard: (value: number | null) => void;
};

const RequestCardBody = ({
  requestProps,
  index,
  totalRequests,
  setModalRemovingCard,
}: RequestCardBodyProps) => {
  const [courseRequest, setCourseRequest] = useState<CurrentRequestData>(requestProps);
  const { grading_periods: gradingPeriods, enrollment_levels: enrollmentLevels } =
    useCourseRequestState();
  const {
    isFacultyPortal,
    portalConfig,
    readOnly: readOnlyPortalStatus,
    studentData: { user_type: userType },
  } = useStudentState();
  const [isSuccessNotifying, setIsSuccessNotifying] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [hasUserApproval, setHasUserApproval] = useState<boolean>(
    userApprovedRequest(courseRequest, isFacultyPortal, userType)
  );
  const [approvalChecked, setApprovalChecked] = useState(
    userApprovedRequest(courseRequest, isFacultyPortal, userType) ||
      courseRequest.approval_status === REQUEST_STATUS.Approved
  );
  const [requestApproved, setRequestApproved] = useState<boolean>(
    courseRequest.approval_status === REQUEST_STATUS.Approved
  );
  const [approvalsNeeded, setApprovalsNeeded] = useState(courseRequest.approvals_currently_needed);
  const [requestPending, setRequestPending] = useState<boolean>(
    courseRequest.approval_status === REQUEST_STATUS.Pending &&
      (hasUserApproval || hasAnyApprovals(courseRequest)) &&
      approvalsNeeded !== 'none'
  );
  const readOnly: boolean =
    readOnlyPortalStatus ||
    (!isFacultyPortal && (requestApproved || requestPending)) ||
    courseRequest.approval_status === REQUEST_STATUS.Denied;
  const approvedByOther: boolean = approvedWithoutUserApproval(
    courseRequest,
    requestApproved,
    hasUserApproval
  );
  const approvalReadOnly: boolean = readOnlyApprovalField(courseRequest, userType, approvedByOther);
  const [showApprovalTooltip, setShowApprovalTooltip] = useState(
    isFacultyPortal &&
      (approvalReadOnly || approvedByOther) &&
      !(courseRequest.approval_status === REQUEST_STATUS.Denied)
  );

  const {
    sendFetchRequest: courseRequestsUpdate,
    error: updateErrorResponse,
    data: requestResponse,
  } = useFetch();

  useEffect(() => {
    if (requestProps) {
      setCourseRequest(requestProps);
    }
  }, [requestProps]);

  useEffect(() => {
    if (requestResponse?.data?.approvals_currently_needed) {
      setApprovalsNeeded(requestResponse.data.approvals_currently_needed);
      setRequestApproved(requestResponse.data.approval_status === REQUEST_STATUS.Approved);
      setRequestPending(
        requestApproved === false &&
          (hasUserApproval === true || requestResponse.data.has_any_approvals) &&
          !approvalsNeeded?.includes('none')
      );
    }
  }, [hasUserApproval, approvalsNeeded, requestApproved, requestResponse?.data]);

  useEffect(() => {
    setShowApprovalTooltip(
      isFacultyPortal &&
        (approvalReadOnly || approvedByOther) &&
        !(courseRequest.approval_status === REQUEST_STATUS.Denied)
    );
  }, [requestApproved]);

  const handleChangeRequest = async (
    field: string,
    selection: string | number | boolean
  ): Promise<any> => {
    setCourseRequest({ ...courseRequest, [field]: selection });
    setErrorMessage(null);
    setIsLoading(true);

    if (field === 'recommended') {
      selection = !courseRequest.recommended;
    } else if (field === 'approval_status') {
      if (!approvedByOther) {
        setHasUserApproval(Boolean(selection));
      }
      setApprovalChecked(Boolean(selection));
    }
    if (field === 'request_group' && typeof selection === 'string' && selection.match(/[^0-9]/))
      return;

    const tempSelection = selection;

    try {
      await handleUpdateRequest(courseRequest.id, field, selection, courseRequestsUpdate);

      if (field === 'request_group') {
        setIsSuccessNotifying(true);
        setTimeout(() => {
          setIsSuccessNotifying(false);
        }, 2000);
      }

      return true; // To allow success notification
    } catch (error) {
      if (error.message && !error.message.startsWith('TinyTds::Error:')) {
        setErrorMessage(error.message);
      } else {
        setCourseRequest({ ...courseRequest, [field]: tempSelection });
        return false;
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveRequest = (requestId: number) => {
    if (requestApproved) return;

    setModalRemovingCard(requestId);
  };
  const courseDescription = (): string => {
    const courseId = courseRequest.course_id ? `${courseRequest.course_id}: ` : '';
    return `${courseId}${courseRequest.course}`;
  };
  const menuPlacement =
    (totalRequests > 2 && [totalRequests - 1, totalRequests - 2].includes(index)) ||
    (totalRequests === 2 && totalRequests - 1 === index)
      ? 'top'
      : 'bottom';
  const readOnlyClasses = readOnly ? 'bg-gray-100' : '';

  return (
    <tr
      id={`request-${courseRequest.id}`}
      className={classNames('w-full text-xs border-b border-gray-300', {
        'bg-gray-100': readOnly || index % 2 !== 0,
        'bg-green-100': readOnlyPortalStatus && courseRequest.enrolled,
      })}
    >
      <td
        className="w-2"
        style={{ backgroundColor: courseRequest.link_style }}
      ></td>

      <td className="w-10 py-1 text-center">
        {readOnlyPortalStatus ? (
          courseRequest.enrolled ? (
            <i className="nc-icon-glyph ui-1_check-curve text-neutral-3"></i>
          ) : (
            '-'
          )
        ) : (
          <input
            type="text"
            value={courseRequest.request_group}
            onChange={(e) => handleChangeRequest('request_group', e.target.value)}
            className={classNames(
              'flex-initial text-[12px] w-7 h-5 mx-1 px-1 border-gray-300',
              readOnlyClasses,
              { 'ring-2 ring-green-4': isSuccessNotifying }
            )}
            disabled={readOnly}
          />
        )}
      </td>
      <td className="text-sm px-[3px] w-52 min-h-[70px]">
        <div className="font-bold">{courseDescription()}</div>
        <div className="italic">{courseRequest.subject}</div>
        {showField('show_level', portalConfig) && (
          <div className="pb-2 w-28">
            <div className="inline pr-1 align-top">Level:</div>
            <CourseRequestFormSelect
              field="enrollment_level"
              options={enrollmentLevels}
              selectedValue={courseRequest.enrollment_level}
              onChange={handleChangeRequest}
              disabled={readOnly}
              containerWidth="64px"
              menuPlacement={menuPlacement}
            />
          </div>
        )}
        {errorMessage && <div className="text-red-3 text-xs">{errorMessage}</div>}
      </td>

      {showField('show_notes', portalConfig) && (
        <td className="pr-[3px] py-1 min-h-[84px] min-w-48">
          <NotesTextarea
            readOnly={readOnly}
            courseRequestNotes={courseRequest.notes || ''}
            onBlur={handleChangeRequest}
            error={updateErrorResponse}
          />
        </td>
      )}

      {showField('show_priority', portalConfig) && (
        <td className="px-[3px] py-1 w-28">
          <CourseRequestFormSelect
            field="priority"
            selectedValue={courseRequest.priority}
            onChange={handleChangeRequest}
            disabled={readOnly}
            menuPlacement={menuPlacement}
          />
        </td>
      )}
      {showField('show_term', portalConfig) && (
        <td className="px-[3px] py-1 w-28">
          <CourseRequestFormSelect
            field="grading_period"
            options={gradingPeriods}
            selectedValue={courseRequest.grading_period}
            onChange={handleChangeRequest}
            disabled={readOnly}
            menuPlacement={menuPlacement}
          />
        </td>
      )}
      {showField('show_recommended', portalConfig) && (
        <td className="px-[3px] w-[85px] text-center">
          <CheckBox
            field={'recommended'}
            checkedValue={courseRequest.recommended}
            onChange={handleChangeRequest}
            disabled={!isFacultyPortal || readOnly}
            styles={classNames('w-3.5 h-3.5', {
              'bg-gray-100': !isFacultyPortal || readOnlyPortalStatus,
              'bg-gray-400':
                (readOnlyPortalStatus && courseRequest.recommended) ||
                (!isFacultyPortal && courseRequest.recommended),
            })}
          />
        </td>
      )}
      {showField('show_approved', portalConfig) && (
        <td className="px-[3px] w-[85px] text-center">
          <div
            className={classNames('group relative', {
              'cursor-help': approvalReadOnly && isFacultyPortal,
            })}
          >
            <CheckBox
              field={'approval_status'}
              checkedValue={approvalChecked}
              onChange={handleChangeRequest}
              disabled={!isFacultyPortal || readOnly || approvalReadOnly}
              styles={classNames('w-3.5 h-3.5', {
                'bg-gray-100 checked:bg-gray-400':
                  !isFacultyPortal ||
                  readOnlyPortalStatus ||
                  (isFacultyPortal && (readOnly || approvalReadOnly)),
              })}
            />
            {showApprovalTooltip && (
              <span className={`${TOOLTIP_CLASSES} right-14 -top-7`}>
                {`No action required. This request ${
                  approvedByOther ? 'has been' : 'should be'
                } approved by someone else.`}
              </span>
            )}
          </div>
        </td>
      )}
      <td className="w-auto">{/* empty */}</td>
      <td className="w-12 p-1 text-center">{(courseRequest.credits || 0).toFixed(2)}</td>
      <td className="w-[84px] p-1 pl-5 text-center">
        {!isLoading && (
          <StatusIcon
            courseRequest={courseRequest}
            requestPending={requestPending}
            requestApproved={requestApproved}
            approvalsNeeded={approvalsNeeded}
            approvalReadOnly={approvalReadOnly}
            portalConfig={portalConfig}
            handleRemoveRequest={handleRemoveRequest}
          />
        )}
      </td>
    </tr>
  );
};

export default RequestCardBody;
