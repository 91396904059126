export type Exam = {
  date: string;
  attendance_taken_on: string | null;
  end_time_calculated: string;
  exam_session_description: string;
  exam_season_description: string;
  exam_season_fk: number;
  room_description: string;
  exam_session_fk: number;
  start_time_calculated: string;
  qualification_description: string;
  awarding_body_description: string;
  is_lead?: boolean;
};

export type ExamComponent = {
  description: string;
  qualification_component_pk: number;
};

export type ExamComponentScore = {
  score: string;
  description: string;
};

export type ExamEntryComponent = {
  exam_entry_component_pk: number;
  grade: string;
  description: string;
  qualification_component_pk: number;
  exam_component_scores: ExamComponentScore[];
};

export type ExamEntry = {
  grade: string;
  exam_entry_pk: number;
  exam_candidacy_fk: number;
  exam_entry_components: ExamEntryComponent[];
};

export type ExamIncident = {
  exam_incident_pk: number;
  description: string;
  incident_time: string;
  notes: string;
};

export type ExamStudent = {
  start_time_override: string;
  end_time_override: string;
  actual_start_time_override: string;
  actual_end_time_override: string;
  first_name: string;
  last_name: string;
  photo_url: string;
  qualification_description: string;
  awarding_body_description: string;
  exam_candidate_session_pk: number;
  notes?: string;
  components: ExamComponent[];
  seat_number: string;
  attendance_status: AttendanceStatus;
};

export type QualificationComponent = {
  description: string;
  notes: string;
};

export type Qualification = {
  description: string;
  awarding_body_description: string;
  components?: QualificationComponent[];
};

export enum PortalType {
  Student = 1,
  Parent = 2,
  Teacher = 3,
}

export enum AttendanceStatus {
  Present = 0,
  Offsite = 1,
  Absent = 2,
}
