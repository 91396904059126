import React from 'react';
import { useExamEntryGrades } from './queries';
import LoadingSpinner from '../../shared/LoadingSpinner';

type ExamGradesProps = {
  personId: number;
  authToken: string;
  examId: number;
};

const ExamGrades = ({ personId, authToken, examId }: ExamGradesProps) => {
  const { data: entry, isLoading } = useExamEntryGrades(personId, authToken, examId);

  if (isLoading || !entry) {
    return (
      <div className="flex justify-center p-8">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="border-t border-gray-200">
      <div className="px-6 py-4">
        <div className="bg-gray-50 p-4 rounded-lg mb-6">
          <h2 className="text-lg font-semibold text-gray-800 mb-2">Overall Grade</h2>
          <p className="text-gray-900 font-semibold">{entry.grade || 'Not yet graded'}</p>
        </div>

        {entry.exam_entry_components?.length > 0 && (
          <>
            <h2 className="text-lg font-semibold text-gray-800 mb-4">Exam Components</h2>
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Description
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Grade
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {entry.exam_entry_components.map((component) => (
                    <React.Fragment key={component.exam_entry_component_pk}>
                      <tr className="bg-gray-50">
                        <td className="px-6 py-4 whitespace-normal text-sm font-medium text-gray-900">
                          {component.description}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                          {component.grade || '-'}
                        </td>
                      </tr>
                      {component.exam_component_scores?.map((score) => (
                        <tr
                          key={`${component.exam_entry_component_pk}-${score.description}`}
                          className="bg-white"
                        >
                          <td className="px-6 py-3 pl-12 whitespace-normal text-sm">
                            {score.description}
                          </td>
                          <td className="px-6 py-3 whitespace-nowrap text-sm">
                            {score.score || '-'}
                          </td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ExamGrades;
