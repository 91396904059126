import React, { useEffect, useState } from 'react';
import { CurrentRequestData, DEFAULT_PRIORITY_OPTIONS, REQUEST_STATUS } from '../types';
import { useCourseRequestState, useStudentState } from '../../../../stores/useCourseRequestsStore';
import RequestCardSelect from './RequestCardSelect';
import { useFetch } from '../../../../hooks/useFetch';
import { handleUpdateRequest } from '../resources';
import RequestCardNotes from './RequestCardNotes';
import { DEFAULT_ERROR_MESSAGE, ERROR_TIMEOUT } from '../../gradebook/constants';
import classNames from 'classnames';
import {
  approvedWithoutUserApproval,
  hasAnyApprovals,
  hasFieldsConfigured,
  isRequestLockedByApproval,
  readOnlyApprovalField,
  userApprovedRequest,
} from '../helpers';
import { showField } from '../helpers';
import StatusIcon from '../shared/StatusIcon';
import { TOOLTIP_CLASSES } from '../constants';

type RequestCardProps = {
  requestProps: CurrentRequestData;
  handleRemoveCard: (number) => void;
};
const RequestCardBody = ({ requestProps, handleRemoveCard }: RequestCardProps) => {
  const { grading_periods: gradingPeriods, enrollment_levels: enrollmentLevels } =
    useCourseRequestState();

  const [request, setRequest] = useState<CurrentRequestData>(requestProps);
  const [errorUpdating, setErrorUpdating] = useState<string>(null);
  const [errorTimeout, setErrorTimeout] = useState<ReturnType<typeof setTimeout>>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    isFacultyPortal,
    readOnly,
    portalConfig,
    studentData: { user_type: userType },
  } = useStudentState();
  const isLocked =
    isRequestLockedByApproval(request, isFacultyPortal) ||
    request.approval_status === REQUEST_STATUS.Denied;
  const checkboxDisabled = readOnly || !isFacultyPortal;
  const {
    sendFetchRequest: updateRequest,
    error: updateErrorResponse,
    data: requestResponse,
  } = useFetch();
  const hasFields = hasFieldsConfigured(portalConfig);
  const [hasUserApproval, setHasUserApproval] = useState<boolean>(
    userApprovedRequest(request, isFacultyPortal, userType)
  );
  const [approvalChecked, setApprovalChecked] = useState(
    userApprovedRequest(request, isFacultyPortal, userType) ||
      request.approval_status === REQUEST_STATUS.Approved
  );
  const [requestApproved, setRequestApproved] = useState<boolean>(
    request.approval_status === REQUEST_STATUS.Approved
  );
  const [approvalsNeeded, setApprovalsNeeded] = useState(request.approvals_currently_needed);
  const [requestPending, setRequestPending] = useState<boolean>(
    request.approval_status === REQUEST_STATUS.Pending &&
      (hasUserApproval || hasAnyApprovals(request)) &&
      approvalsNeeded !== 'none'
  );
  const approvedByOther: boolean = approvedWithoutUserApproval(
    request,
    requestApproved,
    hasUserApproval
  );
  const approvalReadOnly: boolean = readOnlyApprovalField(request, userType, approvedByOther);
  const [showApprovalTooltip, setShowApprovalTooltip] = useState(
    isFacultyPortal &&
      (approvalReadOnly || approvedByOther) &&
      !(request.approval_status === REQUEST_STATUS.Denied)
  );

  useEffect(() => {
    if (requestProps) {
      setRequest(requestProps);
    }
  }, [requestProps]);

  useEffect(() => {
    if (updateErrorResponse) {
      setErrorUpdating(updateErrorResponse?.message || DEFAULT_ERROR_MESSAGE);
      if (errorTimeout) clearTimeout(errorTimeout);

      setErrorTimeout(
        setTimeout(() => {
          setErrorUpdating(null);
        }, ERROR_TIMEOUT)
      );
    }
  }, [updateErrorResponse]);

  useEffect(() => {
    if (requestResponse?.data?.approvals_currently_needed) {
      setApprovalsNeeded(requestResponse.data.approvals_currently_needed);
      setRequestApproved(requestResponse.data.approval_status === REQUEST_STATUS.Approved);
      setRequestPending(
        requestApproved === false &&
          (hasUserApproval === true || requestResponse.data.has_any_approvals) &&
          !approvalsNeeded?.includes('none')
      );
    }
  }, [hasUserApproval, approvalsNeeded, requestApproved, requestResponse?.data]);

  useEffect(() => {
    setShowApprovalTooltip(
      isFacultyPortal &&
        (approvalReadOnly || approvedByOther) &&
        !(request.approval_status === REQUEST_STATUS.Denied)
    );
  }, [requestApproved]);

  const handleSelection = async (field: string, selection: string | number | boolean) => {
    const previousValue = request[field];
    setRequest({ ...request, [field]: selection });
    setErrorUpdating(null);
    setIsLoading(true);

    if (field === 'approval_status') {
      if (!approvedByOther) {
        setHasUserApproval(Boolean(selection));
      }
      setApprovalChecked(Boolean(selection));
    }

    try {
      await handleUpdateRequest(request.id, field, selection, updateRequest);
      return true; // To allow success notification
    } catch (error) {
      setRequest({ ...request, [field]: previousValue });
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const checkboxClasses = (field?: string): string =>
    classNames(`h-4 w-4 rounded-sm ring-transparent`, {
      'bg-[#f2f2f2] border border-[#a6a6a6] checked:bg-[#a6a6a6]':
        readOnly ||
        !isFacultyPortal ||
        isLocked ||
        (approvalReadOnly && field === 'approval_status'),
      'cursor-help': approvalReadOnly && isFacultyPortal && field === 'approval_status',
    });

  const creditsClasses = classNames('text-sm font-light ', {
    'absolute bottom-2.5 right-2.5': hasFields,
  });

  const creditsDisplay = (
    <div className={creditsClasses}>
      {`${request.credits || 0} credit${request.credits !== 1 ? 's' : ''}`}
    </div>
  );

  return (
    <div className="m-5 p-2.5 border border-[#eee] relative max-sm:my-3 max-sm:mx-0">
      {hasFields && creditsDisplay}
      <div className="min-h-7 mb-1 leading-7 flex justify-between">
        <div className="grow mb-1 items-center max-sm:w-[55%]">
          <div className="mr-5 text-[22px] flex max-sm:mr-3">{request.course}</div>
          {!hasFields && creditsDisplay}
        </div>
        <div className="max-sm:self-start">
          {!isLoading && (
            <StatusIcon
              courseRequest={request}
              requestPending={requestPending}
              requestApproved={requestApproved}
              approvalsNeeded={approvalsNeeded}
              approvalReadOnly={approvalReadOnly}
              portalConfig={portalConfig}
              handleRemoveRequest={handleRemoveCard}
            />
          )}
        </div>
      </div>

      <div className="flex justify-between max-sm:flex-col max-sm:mt-1 max-sm:flex-wrap">
        <div className="flex justify-start flex-grow-1 w-full max-sm:mb-1">
          <div className="w-60 mr-4">
            {showField('show_priority', portalConfig) && (
              <div className="flex justify-between p-0.5 items-center h-7">
                <div className="text-xs">Priority</div>
                <RequestCardSelect
                  field="priority"
                  options={DEFAULT_PRIORITY_OPTIONS}
                  selectedValue={request.priority}
                  onChange={handleSelection}
                  readOnly={readOnly || isLocked}
                />
              </div>
            )}
            {showField('show_term', portalConfig) && (
              <div className="flex justify-between p-0.5 items-center h-7">
                <div className="text-xs">Term</div>
                <RequestCardSelect
                  field="grading_period"
                  options={gradingPeriods}
                  selectedValue={request.grading_period}
                  onChange={handleSelection}
                  readOnly={readOnly || isLocked}
                />
              </div>
            )}
            {showField('show_level', portalConfig) && (
              <div className="flex justify-between p-0.5 items-center h-7">
                <div className="text-xs">Level</div>
                <RequestCardSelect
                  field="enrollment_level"
                  options={enrollmentLevels}
                  selectedValue={request.enrollment_level}
                  onChange={handleSelection}
                  readOnly={readOnly || isLocked}
                />
              </div>
            )}
            {showField('show_approved', portalConfig) && (
              <div className="flex justify-between p-0.5 items-center h-6">
                <div className="text-xs">Approved</div>
                <div className="group relative">
                  <input
                    type="checkbox"
                    onChange={(e) => handleSelection('approval_status', e.target.checked)}
                    checked={approvalChecked}
                    className={checkboxClasses('approval_status')}
                    disabled={checkboxDisabled || isLocked || approvalReadOnly}
                  />
                  {showApprovalTooltip && (
                    <span className={`${TOOLTIP_CLASSES} right-6 -top-6`}>
                      {`No action required. This request ${
                        approvedByOther ? 'has been' : 'should be'
                      } approved by someone else.`}
                    </span>
                  )}
                </div>
              </div>
            )}
            {showField('show_recommended', portalConfig) && (
              <div className="flex justify-between p-0.5 items-center h-6">
                <div className="text-xs">Recommended</div>
                <input
                  type="checkbox"
                  onChange={(e) => handleSelection('recommended', e.target.checked)}
                  checked={request.recommended}
                  className={checkboxClasses()}
                  disabled={checkboxDisabled || isLocked}
                />
              </div>
            )}
          </div>
        </div>

        {showField('show_notes', portalConfig) && (
          <RequestCardNotes
            notesValueProps={request.notes || ''}
            onBlur={handleSelection}
            readOnly={readOnly || isLocked}
          />
        )}
        <div className="flex-grow-1 w-full">{/* Empty */}</div>
      </div>
      {errorUpdating && <div className="text-red-500 text-xs mt-2">{errorUpdating}</div>}
    </div>
  );
};

export default RequestCardBody;
