import React from 'react';

import { formatMonetaryValue } from 'components/shared/utils';
import EditPurchaseRequestReceivedItem from '../EditPurchaseRequestReceivedItem';

const formatDate = (value) => moment(value).utc().format('MM-DD-YYYY');

function PurchaseRequestItemReceiving(props) {
  const { data, config, loadItems } = props;

  const [showEditItem, setShowEditItem] = React.useState(false);

  const receivedStatus = config.received_status.options.find(
    (option) => option.id === data.received_status
  )?.description;

  /***************************************************************************************************
   ** Edit
   ***************************************************************************************************/

  const handleClickEditButton = React.useCallback((event) => {
    event.preventDefault();
    setShowEditItem(true);
  }, []);

  /**************************************************************************************************/

  if (showEditItem) {
    return (
      <EditPurchaseRequestReceivedItem
        config={config}
        data={data}
        setShowEditItem={setShowEditItem}
        reloadItems={loadItems}
      />
    );
  }

  return (
    <tr>
      <td className="text-align-left">{data.description}</td>
      <td className="text-align-left">{data.model_number}</td>
      <td className="text-align-right">
        {formatMonetaryValue(data.unit_cost, {
          locale: data.currency_locale,
          code: data.currency_code,
        })}
      </td>
      <td className="text-align-center">{data.quantity}</td>
      <td className="text-align-right">
        {formatMonetaryValue(data.total_cost, {
          locale: data.currency_locale,
          code: data.currency_code,
        })}
      </td>
      <td className="text-align-center">{receivedStatus}</td>
      <td className="text-align-center">{data.received_quantity}</td>
      <td className="text-align-center">
        {data.received_date === null ? '' : formatDate(data.received_date)}
      </td>
      <td className="text-align-left">
        <button
          className="action-button vx-button vx-button--neutral"
          onClick={handleClickEditButton}
        >
          Edit
        </button>
      </td>
    </tr>
  );
}

export default React.memo(PurchaseRequestItemReceiving);
